import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Manufacturing(){

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Manufacturing</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Industries</Link></li>
                                    <li><span>Manufacturing</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="solution-sections" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Internet of Things</h2>
                                <ul>
                                    <li>Smart robotics through automation, connectivity and analytics.</li>
                                    <li>Using connected sensors to monitors robots maintenance needs and trigger repair before parts break.</li>
                                    <li>Companies can integrate sensors to tolls and machines on the shop floor and give workers wearable technology design to reduce errors and increase safety in the workplace.</li>
                                    <li>Warehouses using wi-fi connected robots to have robots locate shelves of products and bringing them to workers.</li>
                                </ul>
                                <a href="/internetofthings" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-iot.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-ai.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Artificial Intelligence</h2>
                                <ul>
                                    <li>machines with automated visual inspection tool to find even microscopic flaws in products. The system will then recognize, detect, marks and send alerts</li>
                                    <li>Other machine are used to detect and predict defects in machinery BEFORE issues arise</li>
                                    <li>Using AI-operated data processing system that reduce environmental impacts</li>
                                </ul>
                                <a href="/artificialintelligence" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Cloud solution</h2>
                                <ul>
                                    <li>Data storage, analytics and intelligence</li>
                                    <li>Scalability solves the problem of providing new servers, computing resource. Saving time and money</li>
                                    <li>Low cost – no hardware necessary</li>
                                </ul>
                                <a href="/cloudsolution" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-cloud.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-security.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Security & Surveillance Solution</h2>
                                <ul>
                                    <li>All-around premise security and surveillance</li>
                                    <li>Video intercom</li>
                                    <li>IP cameras</li>
                                    <li>CCTV Cameras</li>
                                    <li>Reducing risk of breach and increasing peace of mind</li>
                                </ul>
                                <a href="/security" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/industrial-cabling.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Cabling Infrastructure & Data Networking</h2>
                                <ul>
                                    <li>Proper cabling system support high-speed internet</li>
                                    <li>Network cabling, Voice cabling, System cabling, data center</li>
                                    <li>Complete system of cabling
                                        <ul>
                                            <li>Smart building – applying new technologies to solve real challenges</li>
                                            <li>Proper cabling to support fast emerging 5G network</li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="/infrastructure" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Paging System Solution</h2>
                                <ul>
                                    <li>Emergency paging system used during emergencies to quickly notify staff of emergencies</li>
                                    <li>Mass notification as usually manufacturing facilities are relatively huge</li>
                                </ul>
                                <a href="/pagingsystem" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-paging.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/industrial-avs.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Audio-Video Solution</h2>
                                <ul>
                                    <li>Used of IP-enabled professional AV for conference meetings across all board members</li>
                                    <li>Digital signage used as a marketing strategy tool to promote business</li>
                                </ul>
                                <a href="/audiovideo" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Wireless Connection / WiFi</h2>
                                <ul>
                                    <li>Accessing data/analytics stored in the cloud requires internet connection</li>
                                    <li>Great choice for indoor networks</li>
                                    <li>Machines / tools used in operation are mostly run using internet and being able to connect to WiFi is essential</li>
                                </ul>
                                <a href="/wirelessconnection" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-wireless.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/industrial-telecommunication.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Telecom Support</h2>
                                <ul>
                                    <li>Most small warehouses still runs on older generation phone system as upgrading to the cloud is not necessary for them. Support for these legacy phone systems is always available here at <span>ana Technology</span></li>
                                </ul>
                                <a href="/telecommunication" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Manufacturing;