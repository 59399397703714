import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function Infrastructure(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Infrastructure</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Infrastructure</h2>
                            <p>Network Cabling, Voice Cabling, System Cabling, Data Centre</p> 
                            <p>Utility Line Locator – Identify and map location and depth of utilities such as water, sewer, gas, cable, oil and electric lines.</p>       
                            <p>Cabling </p>
                            <ul>
                                <li>A cable system is like a giant network of roads that carries data and voice communication to its end users. With a proper cabling infrastructure, your business will be able to deliver a quick transaction, instant phone conversation, and resource management to your customers</li>
                                <li>It’s the basis for all the communication at your business</li>
                                <li>Connect to the world</li>
                                <li>Complete system of cabling</li>
                                <li>Reliable installation and connectivity</li>
                                <li>Use of Cat5, Cat5e, Cat6, Coax, many more</li>
                                <li>High speed internet</li>
                                <li>Streaming, Web-Browsing, solution for demanding customers</li>
                                <li>high-performance structured cabling allows your business to support the latest edge devices (routers, switches, etc.) with no problem</li>
                            </ul>
                            <p>Smart building – applying new technologies to solve real challenges</p>
                            <ul>
                                <li>Reduce energy consumption</li>
                                <li>Predictive maintenance</li>
                                <li>Increase productivity</li>
                                <li>Better use of resources</li>
                                <li>Proper cabling to support fast emerging 5G network</li>
                                <li>5G enables a new kind of network that is designed to connect virtually everyone and everything together including machines, objects, and devices
                                    <ul>
                                        <li>Two-thirds of large enterprises (5,000+ employees) plan to deploy 5G technology by 2021</li>
                                    </ul>
                                </li>
                                <li>By 2020, an estimated of 26 billion devices will be connected to the internet with many being operated in a commercial building</li>
                            </ul>
                            <p>Data Networking</p>
                            <ul>
                                <li>Communication network that is devoted to carrying computer information – a system that transfer data between network access points</li>
                                <li>Access information across networks, faster</li>
                                <li>Shared information</li>
                                <li>Different types of data network
                                    <ul>
                                        <li>Personal Area Network</li>
                                        <li>Local Area Network</li>
                                        <li>Wireless Local Area Network</li>
                                        <li>Etc.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Data Centre</p>
                            <ul>
                                <li>Centralized location where computing and networking equipment is concentrated for the purpose of collecting, storing, processing, distributing or allowing access to large amounts of data. </li>
                                <li>BICSI Certified. Engineers that are familiar with BICSI standard regulation and are always in the process of enhancing professional’s knowledge and skills
                                    <ul>
                                        <li>Outside-Plant Engineer (OSP) – responsible for the construction and maintenance of plant infrastructure, design, and engineering as well as provide a route analysis of underground and aerial fiber optic installation</li>
                                    </ul>
                                </li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/cabling-Infrastructure.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default Infrastructure;