import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function ArtificialIntelligence(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Artificial Intelligence</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Artificial Intelligence</h2>
                            <p>Examples: Digital personal assistant, machine learning, personalized social media feeds, Google and many more</p>           
                            <ul>
                                <li>virtual assistant</li>
                                <li>facial recognition</li>
                                <li>detection of building occupancy</li>
                                <li>temperature control</li>
                                <li>problem solving capabilities</li>
                                <li>(video streaming services)</li>
                            </ul>
                            <p>Human-like ability to perform tasks</p>
                            <ul>
                                <li>Self-parking vehicles </li>
                                <li>Temperature Control System</li>
                                <li>Get job done faster with less human interaction</li>
                                <li>Researchers have estimated that 47% of US jobs could be automated within the next two decades.</li>
                                <li>Increasing tasks efficiency</li>
                            </ul>
                            <p>Business adoption of AI-powered technologies is expected to triple by 2021, while adoption of edge computing is expected to double.</p>
                            <p>There are 2 types of Artificial Intelligence</p>
                            <ul>
                                <li>Machines that can learn from experience to provide personalized recommendation (Strong AI)</li>
                                <li>Ability to perform define/specific task – repeatedly (Weak AI)</li>
                            </ul>
                            <p>Intelligent Automation</p>
                            <ul>
                                <li>Automation in general is basically making a hardware or software capable of doing things automatically without human intervention. Automation can or can not be based on AI</li>
                                <li>While Intelligent Automation is a combination of Robotic Process Automation and Artificial Intelligence.</li>
                                <li>Allows to use both the benefit of automation - Increased speed, efficiency, time-savings, and ability to scale and insights, flexibility and processing power of AI.</li>
                                <li>Can turn insights into action</li>
                                <li>Implementing Intelligent Automation can help marketers achieve better paid digital campaign results.</li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                            <img src="./Assets/Img/education-ai.png"></img>
                            {/* <img src="./Assets/Img/internet-img.png"></img> */}
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default ArtificialIntelligence;