import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function Consultation(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Consultation</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Consultation</h2>
                            <p>Unique solution for a unique business</p> 
                            <p>Free site visit/survey</p>
                            <ul>
                                <li>Structures evaluation</li>
                                <li>System evaluation</li>
                                <li>Spending on IT evaluation</li>
                                <li>network assessments, disaster planning, document management, and business continuity</li>
                            </ul>
                            <p>Manage licenses</p>
                            <p>Procurement</p>
                            <p>Free quotation </p>
                            <p>Competitive pricing</p>
                            <p>Going above and beyond by accommodating customers specific needs</p>
                            <p>Hands-on</p>
                            <p>All the resource you need under one roof</p>
                            <p>Third-party consultant</p>
                            <p>Phone Bill Audit </p>
                            <p>Wireless Cellphone</p>
                            <p>Cellular signal booster</p>
                            <p>Microwave transmission</p>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/Consultancy-01.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default Consultation;