import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function PagingSystem(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Paging Systems Solutions</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text security-service">
                        
                            <h2>Paging Systems Solutions</h2>
                            <p>Whether the emergency is a medical, safety, security, or weather-related incident, the ability to quickly and clearly inform staff of the emergency and coordinate the appropriate action plan verbally is critical to ensuring everyone’s safety</p> 
                            <ul>
                                <li>Pagers remain a relatively popular option among healthcare organization with 56% still relies on it</li>
                                <li>For Schools, paging system are very reliable as it is one of the tools that is used during emergencies</li>
                                <li>Emergency paging system is also a fast way for individuals with medical problems to get a quick response in times of medical need.</li>
                                <li>Paging system can be used in businesses in the event of medical emergency, fire or even burglary.</li>
                            </ul>
                            <p>Centralized clocks</p>
                            <ul>
                                <li>Synchronize your entire business, facility, office</li>
                                <li>Accurate time is critical in healthcare facilities: standardized schedules, timely medication disbursement, accurate medical record maintenance, complies with timekeeping regulations, etc.</li>
                                <li>In schools centralized clocks are very important because so many events occur throughout the day in which students and teachers must rely on accurate synchronized time.</li>
                            </ul>
                            <p>One-way – mass notification</p>
                            <ul>
                                <li>Ceiling speakers, IP intercom, voice announcers</li>
                            </ul>
                            <p>Two-way – requires response</p>
                            <ul>
                                <li>Walkie-talkie</li>
                            </ul>
                            <p>Unified paging solution</p>
                            <p>One voice to reach all</p>
                            <p>VoIP phone system allows to combine call paging, intercom and all other paging system</p>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                            <img src="./Assets/Img/paging-system.png"></img>
                            {/* <img src="./Assets/Img/internet-img.png"></img> */}
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default PagingSystem;