import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Financial(){

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Financial</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Industries</Link></li>
                                    <li><span>Financial</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="solution-sections" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Internet of Things</h2>
                                <ul>
                                    <li>Customers use smart device for accessing data, which allows banks to provide a complete view of customer finances in real-time while also providing more service such as spending advises. Customer information will also help banks provide financial assistance and customized products.</li>
                                    <li>Predicting fraud in transactions through verifying account holders mobile/device location and transaction location, banks can approve or decline transactions.</li>
                                    <li>Rent cars companies and insurance companies using IoT sensors in vehicles to locate vehicles while also notifying the institution if someone tries to remove sensor, preventing theft and enabling fast recovery of vehicles</li>
                                </ul>
                                <a href="/internetofthings" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-iot.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-ai.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Artificial Intelligence</h2>
                                <ul>
                                    <li>AI Machine that utilize thousand of customer data that helps financial institution to assess borrowers with little to no credit information or history.</li>
                                    <li>Other machine learning software using analytical solutions using a combination of cloud computing and natural language processing</li>
                                </ul>
                                <a href="/artificialintelligence" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Cloud solution</h2>
                                <ul>
                                    <li>Cloud base core banking system</li>
                                    <li>Offer database options to allow financial services to launch and manager their CRM or other database data in the cloud</li>
                                    <li>Provides scalable storage that can be increase or decreased in a matter of seconds through cloud dashboard</li>
                                    <li>Infrastructure as a Service (IAAS) – reduce cost on deploying, testing and running applications on on-premise resources</li>
                                </ul>
                                <a href="/cloudsolution" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-cloud.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/financial-digital-learning.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Digital learning</h2>
                                <ul>
                                    <li>Using e-learning to train employees (?)</li>
                                    <li>Company procedure training can be taught through e-learning using videos</li>
                                </ul>
                                <a href="/remotelearning" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Security & Surveillance Solution</h2>
                                <ul>
                                    <li>All-around premise security and surveillance</li>
                                    <li>Video intercom</li>
                                    <li>IP cameras</li>
                                    <li>CCTV Cameras</li>
                                    <li>Reducing risk of breach and increasing peace of mind</li>
                                </ul>
                                <a href="/security" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-security.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/financial-infrastructure.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Cabling Infrastructure & Data Networking</h2>
                                <ul>
                                    <li>Proper cabling system support high-speed internet</li>
                                    <li>Network cabling, Voice cabling, System cabling, data center</li>
                                    <li>Complete system of cabling
                                        <ul>
                                            <li>Smart building – applying new technologies to solve real challenges</li>
                                            <li>Proper cabling to support fast emerging 5G network</li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="/infrastructure" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Paging System Solution</h2>
                                <ul>
                                    <li>Emergency paging system used during emergencies to quickly notify staff of emergencies</li>
                                    <li>Mass notification</li>
                                </ul>
                                <a href="/pagingsystem" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-paging-system.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/financial-av.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Audio-Video Solution</h2>
                                <ul>
                                    <li>Use of digital signage as a marketing tool to inform, educate and communicate with customers in store and anywhere</li>
                                    <li>Uses of screens at specific locations to target individual</li>
                                    <li>IP-enabled professional AV used in presentations or meeting</li>
                                </ul>
                                <a href="/audiovideo" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Wireless Connection / WiFi</h2>
                                <ul>
                                    <li>Internet connection is required in order to access customer information/data stored in the cloud </li>
                                    <li>Great choice for indoor networks</li>
                                    <li>Wireless transactions</li>
                                </ul>
                                <a href="/wirelessconnection" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-wireless.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/financial-telecom.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Telecom Support</h2>
                                <ul>
                                    <li>Some financial services are still skeptical to move their operation in the cloud and thus leaving them to run their institution using older generation phone system. With our years of expertise, we will be able to support these systems to help keep them running smoothly and efficiently</li>
                                </ul>
                                <a href="/telecommunication" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Financial;