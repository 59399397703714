import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function RenewableEnergy(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Renewable Energy</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Renewable Energy</h2>
                            <p>Naturally-occurring sources that can be constantly replenished</p>
                           <ul>
                                <li>Solar</li>
                                <li>Wind</li>
                                <li>Biomass </li>
                                <li>Geothermal </li>
                                <li>Hydroelectric power</li>
                            </ul>
                            <p>Green power is a subset or a member of renewable energy. Defined as power generated from natural sources.</p>
                            <p>Most green energy sources are renewable, but not all renewable energy sources are wholly green.</p>
                            <ul>
                                <li>Clean – produced with little-to-no environmental impact</li>
                                <li>Varied – wind, geo-thermal, hydro and solar</li>
                                <li>Renewable – not created from finite materials like fossil fuels</li>
                                <li>Stable – can be found anywhere in the planet</li>
                                <li>Inexpensive </li>
                            </ul>
                            <p>Renewable electricity cost is falling and therefore, a lot of telecom services are switching or transitioning over to renewable power</p>
                            <p>Renewable energy also increases the resiliency of networks after storms. Power means critical communication lines stay operational which are essential to businesses.</p> 
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/Renewable-01.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default RenewableEnergy;