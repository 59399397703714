import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function MonitoringService(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Monitoring Service</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Monitoring Service</h2>
                            <p>Commercial intrusion monitoring is a security system that is also called Intrusion Detection System. The role of this system is to monitor a building or premises for suspicious activity and send real-time alerts to professionally trained operators. As an acting monitoring company, such as fire alarms and alerts will be properly handled according to strict procedures to ensure that appropriate personnel are dispatched if required.</p> 
                            <p>Additional information can be gathered through video verification of alarms from your commercial building. This is done through CCTV. Video verification helps cut down false alarms, which are a hustle</p>
                            <p>Provides updates anywhere and anytime as long as a smart device is connected.</p>
                            <p>Applicable to business of all sizes.</p>
                            <ul>
                                <li>Alarm monitoring</li>
                                <li>Video monitoring</li>
                                <li>Intrusion detection</li>
                                <li>Fire panel monitoring</li>
                                <li>Elevator monitoring</li>
                            </ul>
                            <p>Network Operations Center (NOC)</p>
                            
                            <ul>
                                <li>Centralized location where IT technicians directly support the efforts of remote monitoring and management software</li>
                                <li>NOC Engineer monitors and executes the daily operational activities needed to manage IT services and supporting IT infrastructure.
                                    <ul>
                                        <li>Analyze events</li>
                                        <li>Perform troubleshooting</li>
                                        <li>Communicate with site technicians and/or third parties responsible for resolution</li>
                                        <li>Track issues</li>
                                    </ul>
                                </li>
                            </ul>
                            
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/Monitoring-01.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default MonitoringService;