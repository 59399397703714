import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function CloudSolutions(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Cloud Solution</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Cloud Solution</h2>
                            <p>One Number Does It All!</p> 
                            <p>A cloud-based phone system is all you need</p>  
                            <ul>
                                <li>94% already utilizing cloud service</li>
                            </ul>
                            <p>Taking Unified Communication to the next level</p>
                            <ul>
                                <li>83% of workloads will be stored in the cloud by end of 2020</li>
                            </ul>
                            <p>Ease of accessibility anytime, anywhere</p>
                            <p>Lower cost solution for your business as no new hardware is required. Information/data are stored in a cloud-based system</p>
                            <p>Ability to integrate other application into the system</p>
                            <p>Call, chat, message, videocall, fax, collaborate(file-sharing) – allowing you to communicate in one system </p>
                            <ul>
                                <li>Centralized workflow</li>
                            </ul>
                            <p>Strengthen remote workforce- due to unforeseen changes in the way employees has to worked, utilizing cloud solution has been the best option for most businesses so far.</p>
                            <p>Strong connected teams that can share information easily and quickly</p>
                            <p>Wireless communication</p>
                            <p>Small-Medium Businesses, Health, Schools, call centers, contact tracing</p>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                             <img src="./Assets/Img/education-cloud.png"></img>
                            {/* <img src="./Assets/Img/internet-img.png"></img> */}
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default CloudSolutions;