import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function Turnkey(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Turnkey Projects</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Turnkey Projects</h2>
                            <p>With you from start to finish</p> 
                            <ul>
                                <li>New projects</li>
                                <li>Coordination, Consultation, Planning, Executing</li>
                            </ul>
                            <p>Complete package</p>
                            <p>0-100 maximum efficiency</p>
                            <p>Everything you need under one roof</p>
                            <p>Start-up business</p>
                            
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/Turnkey-01.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default Turnkey;