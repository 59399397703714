import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';
import FontAwesome from 'react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';


import {Link, useRouteMatch, useParams } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Input,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
  } from "reactstrap";

  function Contact(){
    
    const api_url = "https://api.anatechnology.net/public/api/";

    useEffect(()=>{

        getSiteInfoData();
       
    },[]);

    const [getSiteInfo, setSiteInfo] = useState([]);
    function getSiteInfoData(){
        fetch(api_url+'general-information')
            .then((response) => response.json())
            .then((responseJson) => {
                setSiteInfo(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }


    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Contact</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>Contact</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="contact">
                <Row>
                    <Col lg="12" className="section-head contact-text">
                        <h2>Find The Perfect <br/>Solution With <span>ana Technology</span></h2>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>            */}
                        <Row>
                        {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                              let phone = "tel:"+response.phone;
                              let head_toll_free = "tel:"+response.head_toll_free;
                             return  <>
                                <Col lg="4" md="12" sm="12" className="contactaddress">
                                <h4>Head Office:</h4> 
                                <p>{response.address}</p>
                                <p>Phone: <a href={phone}>{response.phone}</a></p>
                                <p>Toll Free: <a href={head_toll_free}>{response.head_toll_free}</a></p>
                                </Col>
                            </>
                         }) : null
                        
                        }

                        {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                              let regional_phone = "tel:"+response.regional_phone;
                              let regional_toll_free = "tel:"+response.regional_toll_free;
                             return  <>
                                <Col lg="4" md="12" sm="12" className="contactaddress">
                                <h4>Regional Office:</h4> 
                                <p>{response.alternate_address}</p>
                                <p>Phone: <a href={regional_phone}>{response.regional_phone}</a></p>
                                <p>Toll Free: <a href={regional_toll_free}>{response.regional_toll_free}</a></p>
                                </Col>
                            </>
                         }) : null
                        
                        }

                        {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                              let overseas_phone = "tel:"+response.overseas_phone;
                              let overseas_toll_free = "tel:"+response.overseas_toll_free;
                             return  <>
                                <Col lg="4" md="12" sm="12" className="contactaddress">
                                <h4>Europe and Middle East :</h4> 
                                <p>{response.overseas_address}</p>
                                <p>Phone: <a href={overseas_phone}>{response.overseas_phone}</a></p>
                                <p>Toll Free: <a href={overseas_toll_free}>{response.overseas_toll_free}</a></p>
                                </Col>
                            </>
                         }) : null
                        
                        }
                           
                        </Row>
                        <img src="./Assets/Img/contact-img.png"></img>
                    </Col>
                    
                </Row>
            </Container>

            <Footer page={'contact'} />
        </Container>
    )
}

export default Contact;