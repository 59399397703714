import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Ticker = ({ className, ...rest}) => {
  return (
    <CountUp {...rest} redraw={true}>
      {({ countUpRef , start }) => {
        return (
          <VisibilitySensor
            onChange={start}
            delayedCall>
            <h4 className={className} ref={countUpRef} />
          </VisibilitySensor>
        );
      }}
    </CountUp>
  );
};

export default Ticker;