import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function SIPTrunk(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>SIP Trunk</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text sip-trunk">
                        
                            <h2>SIP Trunk</h2>
                            <p>A Voice over Internet Protocol technology (VoIP) – method of sending voice and other unified communications services over the internet.</p>
                            <p>A virtual phone lines that enable users to make and receive phone calls over the internet to anyone with a phone number</p>
                            <p>Each SIP Trunk support SIP channels. A SIP Trunk can hold an unlimited number of channels</p>
                           <ul>
                                <li>Pay for only the number of channels that your business need and add more anytime.</li>
                                <li>Reduce the number of PRI connections that your business requires</li>
                            </ul>
                            <p>Ability to re-route inbound calls to any destination by redirecting calls to a different number of your desire.</p>
                            <ul>
                                <li>Reduce communication costs</li>
                            </ul>
                            <p>Route multiple area codes to a single location</p>
                            <p>Build an automatic fail-over system into your communications strategy for lines and calls incase main site go down.</p>
                            
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/SIP-01.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default SIPTrunk;