import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function WirelessConnection(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Wireless Connection</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Wireless Connection</h2>
                            <p>Refers to Wireless Local Area Network (LAN) technology</p> 
                            <ul>
                                <li>Used in home or office setting</li>
                                <li>It allows users to connect to the internet technology without the wires, hence “wireless”</li>
                            </ul>
                            <p>Having Wi-Fi access at your business premises is now considered as a necessity for customers.</p>
                            <p>Wi-Fi is also used to overtake your telephone system. VoIP and other internet-based communication technology let your business route calls even if you are out of the office. </p>
                            <p>The next generation of Wi-Fi is called Wi-Fi 6th Generation – also known as AX Wi-Fi. It will be able to:</p>
                            <ul>
                                <li>Connect to more devices simultaneously</li>
                                <li>Use in areas where access points will serve more users</li>
                                <li>Improve speed, increase efficiency and reduced congestion</li>
                                <li>Wi-fi 6 expands the wi-fi band from 80 MHz to 160 MHz</li>
                                <li>With Wi-Fi 6, you can enjoy 8K movies, large file downloads and uploads, and responsive smart home devices – all without buffering.</li>
                                <li>Choice for indoor networks</li>
                                <li>There will be a 12x increase in AR/VR traffic between 2017 and 2022- utilizing Wi-Fi 6 enhanced mobile broadband</li>
                            </ul>
                            <p>We will be able to:</p>
                            <ul>
                                <li>Accurately survey premises to determine access points locations for better wireless connections</li>
                                <li>Give solution that will keep your business running, reducing downtime due to loss connectivity</li>
                                <li>Do new wi-fi installation</li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/wifi-solution.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default WirelessConnection;