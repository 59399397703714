import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function AudioVideo(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Audio Video Solutions</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Audio Video Solutions</h2>
                            <p>Conference calls are essential</p>
                            <ul>
                                <li>Reduce travel costs by meeting in a boardroom setting - Video conferencing has reduced the need for business travel by 47%</li>
                                <li>87% of remote employees say they feel more connected and involved with teams and projects when using video conferencing</li>
                            </ul> 
                            <p>Advanced/ modern devices</p>
                            <ul>
                                <li>IP-enabled professional AV </li>
                            </ul>
                            <p>Smart boardroom – increase productivity</p>
                            <ul>
                                <li>By helping to shorten project timelines, video calling tools can save companies anywhere from 15%-30% of the total project cost</li>
                                <li>77% of remote workers report higher productivity levels when working from home</li>
                            </ul>
                            <p>Integrate to UC tools/apps</p>
                            <p>Full control of the entire boardroom system</p>
                            <p>Sleek and professional meetings/presentations</p>
                            <ul>
                                <li>87% of remote employees say they feel more connected and involved with teams and projects when using video conferencing</li>
                            </ul>
                            <p>Digital signage</p>
                            <ul>
                                <li>Use to inform, entertain, educate and communicate with customers in store and anywhere.</li>
                                <li>Use for business overall marketing strategy</li>
                                <li>Digital signage gives you the ability to target content for individual screens at specific locations or all screens, corporate wide. Your entire network can be managed from any modern browser, anywhere in the world.</li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/audio-video-solution.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default AudioVideo;