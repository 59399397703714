import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Privacy(){
    const api_url = "https://api.anatechnology.net/public/api/";
    useEffect(()=>{
        getPrivacyPolicyData();
    },[]);

    const [getPrivacyPolicy, setPrivacyPolicy,] = useState([]);
    function getPrivacyPolicyData(){
        fetch(api_url+'getPrivacyPolicy')
            .then((response) => response.json())
            .then((responseJson) => {
                setPrivacyPolicy(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Privacy Policy</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>Privacy Policy</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="terms-section">
                <Row>
                    <Col lg="12" className="terms-col section-head">
                        <h3>Privacy</h3>
                        {
                            getPrivacyPolicy.length > 0 ? getPrivacyPolicy.map((response,i)=>{
                                return <div key={"single-term"+i} className="single-term">
                                    <h4 key={"title"+i} >{response.title}</h4>
                                    <p  key={"content"+i} >{response.content}</p>
                                </div>
                            }) : null
                         }
                        {/* <div className="single-term">
                            <h4>DISCLAIMER/LIMITATION of LIABILITY</h4>
                            <p>We do not guarantee, represent, or warrant that your use of our service will be uninterrupted, timely, secure, or error-free.</p>
                            <p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
                            <p>You agree that from time to time, we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</p>
                            <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The products and event information delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties, or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                            <p>In no case shall Ana, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers, or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility.</p>
                            <p>Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
                        </div> */}
                    </Col>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Privacy;