import '../App.css';
import '../Style.css';
import FontAwesome from 'react-fontawesome';

import 'bootstrap/dist/css/bootstrap.css';
import logo from '../logo.png';
import React, { Component, useState, useEffect } from "react";
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import {
    Container,
    Row,
    Col,
    Button,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Modal, 
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "reactstrap";


  

function Footer(props){

    const api_url = "https://api.anatechnology.net/public/api/";

    useEffect(()=>{

        getSiteInfoData();
        getservicesData();
       
    },[]);

  const [loader, setLoader] = useState(false);
  
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const [formSubmitMesage, setformSubmitMesage] = useState('');

  const [userSubscriptionEmail, setSubscriptionEmail] = useState('');

  const [userFirstName, setFirstName] = useState('');
  const [userLastName, setLastName] = useState('');
  const [userEmail, setEmail] = useState('');
  const [userPhone, setPhone] = useState('');
  const [userMessage, setMessage] = useState('');
  const [userService, setService] = useState('');


  const [userFirstNameError, setFirstNameError] = useState(false);
  const [userLastNameError, setLastNameError] = useState(false);
  const [userEmailError, setEmailError] = useState(false);
  const [userPhoneError, setPhoneError] = useState(false);
  const [userMessageError, setMessageError] = useState(false);
const [userServiceError, setServiceError] = useState(false);

  const [getSiteInfo, setSiteInfo] = useState([]);
    function getSiteInfoData(){
        fetch(api_url+'general-information')
            .then((response) => response.json())
            .then((responseJson) => {
                setSiteInfo(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }


  function emptyFields(){
    setFirstName('');
    setLastName('')
    setEmail('');
    setPhone('');
    setMessage('');
    setService('');
    // userFirstName="";

    setInterval(
        () =>
        setformSubmitMesage(''),
        3000
      );
}

function emptyFieldsSubscription(){
    setSubscriptionEmail('');
}

  function validate(){
    let status = true;
    if(userFirstName === ''){
        setFirstNameError(true);
        status = false;
    }
    if(userLastName === ''){
        setLastNameError(true);
        status = false;
    }

    if(userEmail === ''){
        setEmailError(true);
        status = false;
    }
    if(userPhone === ''){
        setPhoneError(true);
        status = false;
    }

    // var pattern = new RegExp(/^[0-9\b]+$/);
    // if (!pattern.test(userPhone)) {
    //         setPhoneError(true);
    //         status = false;
    // }
    // else if(userPhone.length != 10){
    //     setPhoneError(true);
    //     status = false;
    // }

    if(userMessage === ''){
        setMessageError(true);
        status = false;
    }
    if(userService === ''){
        setServiceError(true);
        status = false;
    }

return status;

}

function handleSubmit  (event) {
    //console.log(event.target);
    console.log(window.location.href);
    event.preventDefault();
    if(validate()) {
        setLoader(true);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: userFirstName+' '+userLastName, email:userEmail, service:userService, message:userMessage, formname:window.location.href,phone:userPhone})
    };

    fetch(api_url+'contact', requestOptions)
.then(response => response.json())
.then(data => {
    if(data.success == true){
        // setformSubmitMesage('Form Submitted Successfully');
        setLoader(false);
        emptyFields();
        handleShow();
        
    } else {
        setLoader(false);
        setformSubmitMesage('Fill all fields');
    }
});
} else {
    console.log("validation failed");
}
}

function toggle(){
    setShow(!show);
}

function handleSubmitSubscription (event) {
    console.log("handleSubmitSubscription>>>");
    event.preventDefault();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:userSubscriptionEmail})
    };

    fetch(api_url+'subscription', requestOptions)
.then(response => response.json())
.then(data => {
    if(data.success == true){
       emptyFieldsSubscription();
       console.log("submitted");
       handleShow();
    }
});
}

const [serviceData, setserviceData] = useState([]);
        function getservicesData(){
        fetch(api_url+'categories')
            .then((response) => response.json())
            .then((responseJson) => {
                setserviceData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }




    function ConditionWiseSection(){
        return  <React.Fragment>
            
        <Row className="get-in-touch contact-page-footer">
            <Col lg="7" md="12">
                <div className="section-head">
                    <h2>Get in Touch</h2>
                   
                </div>
                <div className="result full" style={{color:'green',textAlign:'center'}}>{formSubmitMesage}</div>
                <form id="get-in-touch-form" name="get-in-touch-form" onSubmit={handleSubmit}>
                    <div className="half fname">
                        <Input type="text" placeholder="First Name" required value={userFirstName}
                           onChange={(e) => {
                            setFirstName(e.target.value)
                            setFirstNameError(false);
                        }}
                        style={userFirstNameError ? {borderColor:'red'} : {borderColor:'#ccc'}}
                        />
                    </div>
                    <div className="half lname">
                        <Input type="text" placeholder="Last Name" required value={userLastName}
                            onChange={(e) => {
                                setLastName(e.target.value)
                                setLastNameError(false);
                            }}
                            style={userLastNameError ? {borderColor:'red'} : {borderColor:'#ccc'}}
                        />
                    </div>
                    <div className="half email-address">
                        <Input type="email" placeholder="Email" required value={userEmail}
                           onChange={(e) => {
                            setEmail(e.target.value)
                            setEmailError(false);
                        }}
                        style={userEmailError ? {borderColor:'red'} : {borderColor:'#ccc'}} 
                        />
                    </div>
                    <div className="half phone">
                        <Input type="tel" placeholder="Phone Number"  pattern="^\d{3}-\d{3}-\d{4}$|^\d{3}.\d{3}.\d{4}$" required value={userPhone} title="Phone should be in (xxx-xxx-xxxx) or (xxx.xxx.xxxx) format" oninvalid="this.setCustomValidity('Phone should be in (xxx-xxx-xxxx) or (xxx.xxx.xxxx) format')"  onChange={(e) => {
                                        setPhone(e.target.value)
                                        setPhoneError(false);
                                    }}
                                    style={userPhoneError ? {borderColor:'red'} : {borderColor:'#ccc'}} 
                            
                        />
                    </div>
                    <div className="full services">
                            <select className="form-control" required value={userService ? userService : ''} 
                                onChange={(e) => {
                                    setService(e.target.value)
                                    setServiceError(false)
                                }}
                                style={userServiceError ? {borderColor:'red'} : {borderColor:'#ccc'}}
                            >
                                
                                <option value="">Select Service</option>
                                {
                                     serviceData.length > 0 ? serviceData.map((response,i)=>{
                                        //   return  <img key={i} src={response.image}></img>
                                        //let match = userService != null && userService != "" ? '' : userService == response.name   
                                        //let match = userService == response.name ? 'selected' : '';
                                        return <option key={response.name} value={response.name}>{response.name}</option>
                                        }) : null

                                }
                            </select>
                        </div>
                    <div className="full message">
                        <Input type="textarea" placeholder="Message" required value={userMessage} onChange={(e) => {
                                setMessage(e.target.value)
                                setMessageError(false)
                            }}
                            style={userMessageError ? {borderColor:'red'} : {borderColor:'#ccc'}} />
                    </div>
                    <div className="full submit-btn">
                    {
                                loader ? 'loading...' : <Button color="primary" type="submit" className="btn-animate">Connect</Button>
                    }
                        
                    </div>
                </form>
            </Col>
            <Col lg="5" md="12">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.3553535264064!2d16.823253915375503!3d52.382108154192125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470445a068520b01%3A0x1ebfec5742ad1f11!2sKaliowa%2040%2C%2060-175%20Pozna%C5%84%2C%20Poland!5e0!3m2!1sen!2s!4v1617952926581!5m2!1sen!2s" width="100%" height="450" style={{marginTop:'125px', borderWidth:0, float:'left'}} allowfullscreen="" loading="lazy"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.4930236469086!2d-113.59309768457544!3d53.566817065875824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a02145dbe44449%3A0x946f5538670c087e!2s200-15606%20116%20Ave%20NW%2C%20Edmonton%2C%20AB%20T5M%203S5%2C%20Canada!5e0!3m2!1sen!2sus!4v1621512008344!5m2!1sen!2sus" width="100%" height="450" style={{marginTop:'125px', borderWidth:0, float:'left'}}  loading="lazy"></iframe>
            </Col>
        </Row>
        
        <Row className="subscribe">
      
            <Col lg="3" md="6" sm="6" className="half">
            
                <FontAwesome name="phone" />
                <div>
                   {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                             return  <h3 key={"phone"+i}>{response.head_toll_free}</h3>
                         }) : null
                   }
                   {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                              let str = "mailto:"+response.email
                             return  <a href={str} key={"email"+i}>{response.email}</a>
                         }) : null
                   }
                    
                </div>
            </Col>
            <Col lg="3" md="6" sm="6" className="half">
                <FontAwesome name="map-marker" />
                <div>
                    <h6>Head Office</h6>
                    {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                             return  <p key={"address"+i}>{response.address}</p>
                         }) : null
                   }
                    
                </div>
            </Col>
            <Col lg="3" md="6" sm="6" className="half">
                <FontAwesome name="map-marker" />
                <div>
                    <h6>Regional Office</h6>
                    {
                          getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                             return  <p key={"alternate_address"+i}>{response.alternate_address}</p>
                         }) : null
                   }
                    
                </div>
            </Col>
            <Col lg="3" md="6" className="full">
                <form id="subscription-form" onSubmit={handleSubmitSubscription}>
                    <div className="subs-email">
                    <Input type="email" required placeholder="Enter Your Email" onChange={(e) => setSubscriptionEmail(e.target.value)} 
                    value={userSubscriptionEmail} />
                    </div>
                    <div className="full submit-btn">
                        <Button color="primary" type="submit" >Subscribe</Button>
                    </div>
                </form>
            </Col>
        </Row>
        {/* <Modal show={show}> */}

        <Modal isOpen={show} fade={true} centered={true} className="modal-lg modalTransition">
        <button className="close" style={{ position: 'absolute', top: '15px', right: '15px', zIndex:2, color:'#000', opacity:1 }} onClick={toggle}>&times;</button>        
        <ModalBody closeButton>
        <Row className="get-in-touch get-touch-popup row-eq-height">
        <Col lg="6">
        {/* <img alt="Sucess" width="200" height="200" src="https://image.flaticon.com/icons/png/512/463/463574.png"></img> */}
        <><p style={{color:'green'}}>Form has been successfully submitted</p></>
        </Col>
        </Row>
        </ModalBody>
        
      </Modal>
        </React.Fragment>
    }

    return(
        <Container className="footer-section" fluid>
                <Row>
                    <Container>
                        {props.page ? ConditionWiseSection() : ConditionWiseSection() 
            //             <React.Fragment>
            //             <Row className="get-in-touch">
            //                 <Col lg="4" md="12" className="section-head">
            //                     <h2>Get in Touch</h2>
            //                 </Col>
            //                 <Col lg="8" md="12">
                            
            // <form id="get-in-touch-form" name="get-in-touch-form" onSubmit={handleSubmit}>
                            
            //         <div className="half fname">
            //             <Input type="text" placeholder="First Name"
            //                onChange={(e) => {
            //                 setFirstName(e.target.value)
            //                 setFirstNameError(false);
            //             }}
            //             style={userFirstNameError ? {borderColor:'red'} : {borderColor:'#ccc'}}
            //             value={userFirstName}
            //             />
            //         </div>
            //         <div className="half lname">
            //             <Input type="text" placeholder="Last Name"
            //                 onChange={(e) => {
            //                     setLastName(e.target.value)
            //                     setLastNameError(false);
            //                 }}
            //                 style={userLastNameError ? {borderColor:'red'} : {borderColor:'#ccc'}}
            //                 value={userLastName}
            //             />
            //         </div>
            //         <div className="half email-address">
            //             <Input type="email" placeholder="Email"
            //                onChange={(e) => {
            //                 setEmail(e.target.value)
            //                 setEmailError(false);
            //             }}
            //             style={userEmailError ? {borderColor:'red'} : {borderColor:'#ccc'}}
            //             value={userEmail} 
            //             />
            //         </div>
            //         <div className="half phone">
            //             <Input type="number" placeholder="Number"
            //                  onChange={(e) => {
            //                     setPhone(e.target.value)
            //                     setPhoneError(false);
            //                 }}
            //                 style={userPhoneError ? {borderColor:'red'} : {borderColor:'#ccc'}}
            //                 value={userPhone}  
            //             />
            //         </div>
            //         <div className="full message">
            //             <Input type="textarea" placeholder="Message" onChange={(e) => {
            //                     setMessage(e.target.value)
            //                     setMessageError(false)
            //                 }}
            //                 style={userMessageError ? {borderColor:'red'} : {borderColor:'#ccc'}}
            //                 value={userMessage}
            //                  />
                              
            //         </div>
            //         <div className="full submit-btn">
            //         {
            //                     loader ? 'loading...' : <Button color="primary" type="submit" className="btn-animate">Contact</Button>
            //         }
                        
            //         </div>
                    
            //     </form>
            //                 </Col>
            //             </Row>
                        
            //             <Row className="subscribe">
            //                 <Col lg="3" md="6" sm="6" className="half">
            //                     <FontAwesome name="mobile" />
            //                     <div>
            //                         <h3>+123 123 123</h3>
            //                         <Link to="/">email@example.com</Link>
            //                     </div>
            //                 </Col>
            //                 <Col lg="3" md="6" sm="6" className="half">
            //                     <FontAwesome name="map-marker" />
            //                     <div>
            //                         <p>Lorem Ipsum Is Simply Dummy Text Lorem</p>
            //                     </div>
            //                 </Col>
            //                 <Col lg="6" md="12" className="full">
            //                     <form id="subscription-form" onSubmit={handleSubmitSubscription}>
            //                         <div className="subs-email">
            //                             <Input type="email" placeholder="Enter Your Email"
            //                                 onChange={(e) => setSubscriptionEmail(e.target.value)}
            //                                 value={userSubscriptionEmail} 
            //                             />
            //                         </div>
            //                         <div className="full submit-btn">
            //                             <Button color="primary" type="submit" >Subscribe</Button>
            //                         </div>
            //                     </form>
            //                 </Col>
            //             </Row>
            //             </React.Fragment>
                          /* } */}
                        <Row className="footer-widget">
                            <Col lg="4" md="12" className="about">
                                <h4>About</h4>
                                <p><span>ana Technology</span> is a leading technology provider that caters to several industry types. We offer specialized services and innovative solutions, upgrading the way you do business.</p>
                            </Col>
                            <Col lg="3" md="12" className="quick-links">
                                <h4>Quick Links</h4>
                                <ul>

                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                    <li><Link to="/terms">Term & Condition</Link></li>
                                    <li style={{visibility:'hidden'}}><Link to="/">#</Link></li>
                                </ul>
                            </Col>
                            <Col lg="3" md="12" className="quick-links">
                                <h4>Services</h4>
                                <ul>
                                    <li><Link to="/internetofthings">Internet of Things</Link></li>
                                    <li><Link to="/artificialintelligence">Artificial Intelligence</Link></li>
                                    <li><Link to="/audiovideo">Audio Video Solution</Link></li>
                                    <li><Link to="/cloudsolution">Cloud Solution</Link></li>
                                    <li><Link to="/remotelearning">Remote Learning</Link></li>
                                    <li><Link to="/infrastructure">Infrastructure</Link></li>
                                </ul>
                            </Col>
                            <Col lg="2" md="12" className="social">
                                <h4>Social</h4>
                                <a href="https://www.facebook.com/A-n-a-109288867489668" target="_blank"><FontAwesome name="facebook-f" /></a>
                                <a href="https://twitter.com/TelecomAna" target="_blank"><FontAwesome name="twitter" /></a>
                                <a href="https://www.instagram.com/anatechnology/" target="_blank"><FontAwesome name="instagram" /></a>
                                <a href="https://www.linkedin.com/company/68164671/admin/" target="_blank"><FontAwesome name="linkedin" /></a>
                            </Col>
                        </Row>
                        <Row className="footer-copyright">
                            <Col lg="12" md="12">
                                <p>&copy; 2021 . All Rights Reserved.</p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
    )
}

export default Footer;