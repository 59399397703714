import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function Telecommunication(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Telecommunication</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Telecommunication</h2>
                            <p>Older generation phone systems have supported businesses for years. But with the ever-changing technology and the increasing cost along with it, business have opted out of these upgrades. And that is okay! Here at <span>ana Technology</span>, our experienced expertise will keep your legacy phone system running smoothly and efficiently.</p> 
                            <p>Legacy phone system support</p>
                            <ul>
                                <li>Avaya system
                                    <ul>
                                        <li>Blue</li>
                                        <li>Red</li>
                                    </ul>
                                </li>
                                <li>Nortel</li>
                                <li>Norstar</li>
                                <li>Meridian</li>
                                <li>BCM 50, 200, 400, 450</li>
                            </ul>
                            <p>Newest phone systems including, but not limited to:</p>
                            <ul>
                                <li>Cisco
                                    <ul>
                                        <li>Cisco UC320, UC520, Call Manager</li>
                                    </ul>
                                </li>
                                <li>Avaya IP Office 500 v2</li>
                                <li>Avaya IP Office Server Edition</li>
                                <li>Avaya IP Office 400</li>
                                <li>Avaya Red Solutions 
                                    <ul>
                                        <li>Avaya S8300, S8400, S8500, S8700, Aura</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>PBX – connects telephones within a company to one another and also connects them to public switched telephone network. Lines are shared that’s why it is cost effective</p>
                            <p>Key System – controlled and supported by a Key System Unit that offers multiple lines</p>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/telecom-support.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default Telecommunication;