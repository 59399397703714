import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function InternetOfThings(){

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Education</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Industries</Link></li>
                                    <li><span>Education</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="solution-sections" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Internet of Things</h2>
                                <ul>
                                    <li>Smart Classrooms</li>
                                    <li>IoT allows schools to streamline operations, detect student presence, personalized learning and deliver lessons directly to each student’s device.</li>
                                    <li>Real-time data collection that tracks safety, students’ progress, and many more.</li>
                                    <li>Use of sensors to operate lightings, locks, thermostats, speakers, doorbells in schools.</li>
                                </ul>
                                <a href="/internetofthings" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/education-iot.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/education-ai.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Artificial Intelligence</h2>
                                <ul>
                                    <li>Developed engaging, interactive classes led by a virtual instructor</li>
                                    <li>Intelligent tutoring system- organized around students’ progress, combined with timely targeted feedback, rich activities.</li>
                                    <li>AI tools that will help make global classrooms available to all including those who speak different languages, impaired visually or hearing.</li>
                                    <li>Able to detect count of students</li>
                                </ul>
                                <a href="/artificialintelligence" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Cloud solution</h2>
                                <ul>
                                    <li>Cloud-base phone system that allow teachers and students to communicate through videocall, chat, message and collaborate.</li>
                                    <li>Reducing cost for maintenance of hardware</li>
                                    <li>Using cloud promote or increase student engagement</li>
                                    <li>Increase enrolment targets as geographic barrier is eliminated</li>
                                </ul>
                                <a href="/cloudsolution" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/education-cloud.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/digital-learning.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Digital learning</h2>
                                <ul>
                                    <li>Interactive whiteboards</li>
                                    <li>Video collaboration tools that enables in-class and at home students to interact with each other like they would normally if they come together in the classroom.</li>
                                    <li>Digital learning broadens reach of schools to accept more students outside geographic region</li>
                                </ul>
                                <a href="/remotelearning" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Telecom support</h2>
                                <ul>
                                    <li>Some schools still run traditional phone system – such systems require years of expertise to fix and some may be too outdated</li>
                                </ul>
                                <a href="/telecommunication" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/telecom-support.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/security.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Security & Surveillance Solution</h2>
                                <ul>
                                    <li>All-around premise security and surveillance</li>
                                    <li>Video intercom</li>
                                    <li>IP cameras</li>
                                    <li>CCTV Cameras</li>
                                    <li>Reducing risk of breach and increasing peace of mind</li>
                                </ul>
                                <a href="/security" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Cabling Infrastructure & Data Networking</h2>
                                <ul>
                                    <li>Sharing of information and data is a given in a school setting and therefore, a reliable cable system is necessary in order for information to flow smoothly across network.</li>
                                    <li>Proper cabling system also support high-speed internet</li>
                                    <li>Network cabling, Voice cabling, System cabling, data center</li>
                                    <li>Complete system of cabling
                                        <ul>
                                            <li>Smart building – applying new technologies to solve real challenges</li>
                                            <li>Proper cabling to support fast emerging 5G network</li>
                                            <li>5G enables a new kind of network that is designed to connect virtually everyone and everything together including machines, objects, and devices.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="/infrastructure" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/cabling-Infrastructure.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/paging-system.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Paging System Solution</h2>
                                <ul>
                                    <li>Emergency paging system is most important as it can quickly and clearly inform staff and students of emergencies and coordinate appropriate action plan</li>
                                    <li>Centralized clocks – teachers and students rely on accurate synchronized time</li>
                                    <li>VoIP phone system allows to combine call paging, intercom and all other paging system</li>
                                </ul>
                                <a href="/pagingsystem" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Audio/Video Solution</h2>
                                <ul>
                                    <li>Video conferencing devices used to utilize digital learning across students in-class or at home</li>
                                </ul>
                                <a href="/audiovideo" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/audio-video-solution.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/wifi-solution.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Wireless Connection / WiFi</h2>
                                <ul>
                                    <li>Wifi is very relative into everything that we do now. Schools are moving in a fast phase environment where connection to the internet is critical to run everyday schedule. Accessing information and lessons online and even to access or download files, Wifi is needed.</li>
                                    <li>Connect to internet wirelessly, efficiently.</li>
                                </ul>
                                <a href="/wirelessconnection" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default InternetOfThings;