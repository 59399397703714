import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Hospitality(){

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Hospitality</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Industries</Link></li>
                                    <li><span>Hospitality</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="solution-sections" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Internet of Things</h2>
                                <ul>
                                    <li>Using electronic key cards to hotel guest mobile phones. With sensors, these mobile phones can communicate to unlock the door.</li>
                                    <li>Automated room for hotel guests where they can adjust lights via mobile device</li>
                                    <li>Mobile integration gives you data ( with guest permission) that let’s you remember guest preferences.</li>
                                </ul>
                                <a href="/internetofthings" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/hospitality-iot.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/hospitality-ai.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Artificial Intelligence</h2>
                                <ul>
                                    <li>In-person customer service using robots</li>
                                    <li>Ai chatbots on social media platforms</li>
                                </ul>
                                <a href="/artificialintelligence" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Cloud solution</h2>
                                <ul>
                                    <li>Scalability – increase or decrease depending on seasonal demands</li>
                                    <li>Integrate other tools to improve guest experience</li>
                                    <li>Lower cost – no hardware necessary</li>
                                    <li>Quicker service – checking in and out can be done online y guests</li>
                                </ul>
                                <a href="/cloudsolution" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/hospitality-cloud.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/hospitality-digital-learning.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Digital learning</h2>
                                <ul>
                                    <li>Using e-learning to train employees (?)</li>
                                    <li>Company procedure training can be taught through e-learning using videos</li>
                                </ul>
                                <a href="/remotelearning" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Security & Surveillance Solution</h2>
                                <ul>
                                    <li>All-around premise security and surveillance</li>
                                    <li>Video intercom</li>
                                    <li>IP cameras</li>
                                    <li>CCTV Cameras</li>
                                    <li>Reducing risk of breach and increasing peace of mind</li>
                                </ul>
                                <a href="/security" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/hospitality-unified.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/hospitality-cabling.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Cabling Infrastructure & Data Networking</h2>
                                <ul>
                                    <li>Sharing of information and data is a given in a school setting and therefore, a reliable cable system is necessary in order for information to flow smoothly across network.</li>
                                    <li>Proper cabling system also support high-speed internet</li>
                                    <li>Network cabling, Voice cabling, System cabling, data center</li>
                                    <li>Complete system of cabling
                                        <ul>
                                            <li>Smart building – applying new technologies to solve real challenges</li>
                                            <li>Proper cabling to support fast emerging 5G network</li>
                                            <li>5G enables a new kind of network that is designed to connect virtually everyone and everything together including machines, objects, and devices.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="/infrastructure" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Paging System Solution</h2>
                                <ul>
                                    <li>Emergency paging system used during emergencies to quickly notify staff/employees of any emergencies</li>
                                    <li>Two-way paging where staff users pagers to communicate with a specific person</li>
                                </ul>
                                <a href="/pagingsystem" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/hospitality-paging.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/hospitality-avs.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Audio/Video Solution</h2>
                                <ul>
                                    <li>digital signage use to entertain, educate and communicate with guest on premise</li>
                                    <li>target individual screens at specific locations or all screens</li>
                                    <li>smart boardroom – enabling board members to communicate in a conference calls</li>
                                </ul>
                                <a href="/audiovideo" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Wireless Connection / WiFi</h2>
                                <ul>
                                    <li>Guest access to rooms are now becoming wireless – ability to tap in mobile device to enter rooms – requires internet connection</li>
                                    <li>Having a wifi access in a hotel setting is also a necessity for guest as guest still requires to be connected, especially those in a business trip</li>
                                </ul>
                                <a href="/wirelessconnection" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/hospitality-wireless.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/hospitality-telecommunication.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Telecom support</h2>
                                <ul>
                                    <li>Most local hotels still runs on older generation phone system as upgrading to the cloud is not necessary for them. Support for these legacy phone systems is always available here at <span>ana Technology</span></li>
                                </ul>
                                <a href="/telecommunication" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Hospitality;