import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';
import FontAwesome from 'react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';

// import faStyles from 'font-awesome/css/font-awesome.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link, useRouteMatch, useParams } from 'react-router-dom';
import Slider from "react-slick";

import {
    Container,
    Row,
    Col,
    Input,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';
  function About(){
    const api_url = "https://api.anatechnology.net/public/api/";
        const [nav1, setNav1] = useState(null);
        const[nav2, setNav2] = useState(null);
        const [modal, setModal] = useState(false);

        useEffect(() => {
            getTestimonialsData();
        },[])

        function toggle(){
            setModal(!modal);
        }

        const [testimonialsData, settestimonialsData] = useState([]);
        function getTestimonialsData(){
            fetch(api_url+'testimonials')
                .then((response) => response.json())
                .then((responseJson) => {
                    settestimonialsData(responseJson.data);
                })
                .catch((error) => {
                console.error(error);
                });
        }

      const testimonialSlider = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 15000,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 15000,
                slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 15000,
                slidesToScroll: 1,
            }
          }
          ]
      };

      const testimonialSliderImg = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 15000,
        centerMode: true,
        focusOnSelect: true,
        
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 2,
                
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              dots: false,
              infinite: true,
              slidesToShow: 2,
              
              
              slidesToScroll: 1,
            }
          }
          ]
      };


    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">About Us</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>About us</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="about-s02" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height reverse-column">
                            <Col lg="6" md="6" sm="12" className="section-head about-text">
                                <h2>About Us</h2>
                                <p>Although relatively new, we’ve quickly escalated up the ladders of digital intervention providing companies. At <span>ana Technology</span>, our team includes tech wizards who’ve eons of experience working with several industries. Over the years, we’ve helped companies integrate smart solutions into their business models, offering innovative techniques and turnkey solutions. </p>
                                <p>Whether it’s IoT, cloud solutions, machine learning, or audio-video solutions, we’re braced with state-of-the-art technologies, helping you every step of the way.</p>
                                <Link to="#" onClick={toggle} className="btn-animate">Let's Talk</Link>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="about-img">
                                <img src="./Assets/Img/about_new.png"></img>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="about-s02 about-s03" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height">
                            <Col lg="6" md="6" sm="12" className="about-img">
                                <img src="./Assets/Img/about-s03-img.png"></img>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="section-head about-text">
                                <h2>Why Choose Us?</h2>
                                <ul>
                                    <li>Digitalize your business quickly and effectively.</li>
                                    <li>Incorporate updated surveillance systems for efficient monitoring.</li>
                                    <li>Train your employees in a virtual classroom setup.</li>
                                    <li>Speed up the medical diagnostics and treatment procedures.</li>
                                    <li>Connect with your clients globally using smart WIFI setups.</li>
                                </ul>
                                <Link to="#" onClick={toggle} className="btn-animate">Let's Talk</Link>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="testimonials testi-inner" fluid>
                <Row>
                        <Container>
                <Row>
                    <Col lg="12" className="section-head">
                        <h2>Testimonials</h2>
                    </Col>
                    <Col lg="12" className="testimonial-section">
                    
                        <Slider className="testimonial-slider-img" {...testimonialSliderImg} 
                        asNavFor={nav2}
                        ref={slider => { setNav1(slider)}}
                        >
                            {/* <div>
                                <img src="./Assets/Img/testimonial-1.png"></img>
                            </div>
                            <div>
                                <img src="./Assets/Img/testimonial-2.png"></img>
                            </div>
                            <div>
                                <img src="./Assets/Img/testimonial-3.png"></img>
                            </div>
                            <div>
                                <img src="./Assets/Img/testimonial-1.png"></img>
                            </div>
                            <div>
                                <img src="./Assets/Img/testimonial-2.png"></img>
                            </div>
                            <div>
                                <img src="./Assets/Img/testimonial-3.png"></img>
                            </div> */}

                            {
                            testimonialsData.length > 0 ? testimonialsData.map((response,i)=>{
                                        //   return  <img key={i} src={response.image}></img>
                                        return <div key={i}>
                                        <img key={i} src={response.image}></img>
                                    </div>

                                        }) : null
                            }
                        </Slider>
                    
                    
                        <Slider className="testimonial-slider" {...testimonialSlider}
                        asNavFor={nav1}
                        ref={slider => { setNav2(slider)}} >
                            {/* <div>
                                <div className="inner-testimonials">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                                    <strong>Silvia Simon</strong>
                                    <span>Customer</span>
                                </div>
                            </div> */}
                            {
                            testimonialsData.length > 0 ? testimonialsData.map((response,i)=>{
                                        //   return  <img key={i} src={response.image}></img>
                                        return <div key={i}>
                                        <div className="inner-testimonials">
                                            <p>{response.description}</p>
                                             <strong>{response.name}</strong>
                                        <span>Customer</span>
                                </div>
                                    </div>

                                        }) : null
                            }
                        </Slider>
                    
                    </Col>
                </Row>
                </Container>
                </Row>
                <ModalComponent modal={modal} toggle={() => toggle()}/>
            </Container>

            <Footer/>
        </Container>
    )
}

export default About;