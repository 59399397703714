import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function UnifiedCommunication(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Unified Communication</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Unified Communication</h2>
                            <p>Allow your business to communicate in different ways all in one system!</p> 
                            <p>Call, chat, video, email, fax, etc.</p>  
                            <p>On premise PBX solution</p>        
                            <ul>
                                <li>More control</li>
                                <li>Signaling that occurs back and forth inside the premise</li>
                                <li>Ability to connect to local providers through traditional phone lines</li>
                            </ul>
                            <p>Will allow employees to participate in more productive meetings and better management of daily communications.</p>
                            <p>Unified communication as a service is a cloud-based solution that consolidates all of business’s communication needs into one solution and is hosted by a single vendor in the cloud.</p>
                            <p>Contact Centre</p>
                            <ul>
                                <li>Can be integrated with contact center software that will improve both the agent and customer experience – combined use of communications and collaboration solutions. </li>
                                <li>Connect with customers via multiple channels such as email, social media, web chat, and telephone.</li>
                                <li>Home and mobile workers can keep in touch and respond at once.</li>
                                <li>Decision can be made faster and travel time and expenses can be reduced.</li>
                                <li>Widely used in healthcare, government agencies, customer care, etc.</li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/internet-img.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default UnifiedCommunication;