import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Transportation(){

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Transportation</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Industries</Link></li>
                                    <li><span>Transportation</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="solution-sections" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Internet of Things</h2>
                                <ul>
                                    <li>Using sensor data that tracks everything from anomalies in train speeds, roadway temperature, aircraft part condition, to the number of cars waiting at an intersection</li>
                                    <li>Using network of sensors, digital cameras and communication system that can increase capacity and enhance passenger safety</li>
                                    <li>Using sensors that automatically displays real-time road status, toll rates, lane closures and travel times</li>
                                    <li>Autonomous cars – equipped with sensors, self-driving cars are able to recognize road signs and markings</li>
                                    <li>Software-reliant components of cars can be updated “over the air” </li>
                                    <li>Parking: private garages that uses sensors to open gate or garage to paying users</li>
                                    <li>IoT sensors in CCTV cameras and along highways and bridges collect data to help reduce traffic burdens and predict likely congestion</li>
                                </ul>
                                <a href="/internetofthings" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/transport-iot.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/transport-ai.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Artificial Intelligence</h2>
                                <ul>
                                    <li>Automated vehicles /autonomous vehicles</li>
                                </ul>
                                <a href="/artificialintelligence" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Cloud solution</h2>
                                <ul>
                                    <li>Store more fleet data on the cloud server</li>
                                    <li>using software that enable users to have real-time access to any critical information about vehicles.</li>
                                    <li>Low cost- no hardware necessary</li>
                                </ul>
                                <a href="/cloudsolution" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/transport-cloud.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/transport-security.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Security & Surveillance Solution</h2>
                                <ul>
                                    <li>All-around premise security and surveillance</li>
                                    <li>Video intercom</li>
                                    <li>IP cameras</li>
                                    <li>CCTV Cameras</li>
                                    <li>Reducing risk of breach and increasing peace of mind</li>
                                </ul>
                                <a href="/security" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Cabling Infrastructure & Data Networking</h2>
                                <ul>
                                    <li>Proper cabling system support high-speed internet</li>
                                    <li>Network cabling, Voice cabling, System cabling, data center</li>
                                    <li>Complete system of cabling
                                        <ul>
                                            <li>Smart building – applying new technologies to solve real challenges</li>
                                            <li>Proper cabling to support fast emerging 5G network</li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="/infrastructure" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/transport-cabling.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/transport-paging-system.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Paging System Solution</h2>
                                <ul>
                                    <li>Emergency paging system used during emergencies to quickly notify staff/passengers of emergencies</li>
                                    <li>Mass notification – used for announcements during transportation in public transit</li>
                                </ul>
                                <a href="/pagingsystem" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Audio-Video Solution</h2>
                                <ul>
                                    <li>Digital signage use to entertain, educate, and communicate with riders</li>
                                </ul>
                                <a href="/audiovideo" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/transport-avs.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/transport-wireless.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Wireless Connection / WiFi</h2>
                                <ul>
                                    <li>Machines / tools used in operation are mostly run using internet and being able to connect to WiFi is essential</li>
                                    <li>In order to transfer data in real-time requires internet</li>
                                </ul>
                                <a href="/wirelessconnection" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        
                    </Container>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Transportation;