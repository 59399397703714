import '../App.css';
import '../particles.css';
import '../Style.css';

import 'bootstrap/dist/css/bootstrap.css';
import logo from '../logo.png';
import React, { Component, useState, useEffect } from "react";
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

import {
    Container,
    Row,
    Col,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Input,
    
  } from "reactstrap";
import ModalComponent from '../Components/ModalComponent';



function Header(props){

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [modal, setModal] = useState(false);
    const [ddmenu, setDDMenu] = useState(false);
    const [solutionmenu,setSolutionmenu] = useState(false);
    const [menushow, setMenuShow] = useState(false);
    const [iconmobile, setIconmobile] = useState(false);
    const [iconchange, setIconchange] = useState(false);
       
        function toggle(){
            setModal(!modal);
        }

        function activateLasers(){
            setMenuShow(!menushow);
            setIconchange(!iconchange);
        }

        function DDMenu(){
            setDDMenu(!ddmenu);
        }
        function SolutionMenu(){
            setSolutionmenu(!solutionmenu);
        }

        
       
    return(
        
        <Container className="header">
                <Row>
                
                    <Navbar sticky="top" expand="lg" fluid="true">
                        <Col lg="2" md="2" sm="4" xs="6" className="logo">
                            <Link to="/">
                                <img src={logo}/>
                            </Link>
                        </Col>
                        <Col lg="8" md="6" sm="6" xs="6" className="main-menu">
                            
                            <ul className={menushow === true ? 'slide' : ''}>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About us</Link></li>
                                <li  className= {ddmenu === true ? "dropdown dd-respo": "dropdown"} onClick={DDMenu}>
                                    <Link to="#">Services <FontAwesome name="angle-down" /></Link>
                                    {ddmenu === true && <div className="submenu-close">
                                            <FontAwesome name='close' onClick={DDMenu}/>
                                        </div> 
                                        }
                                        <div className="dropdown-menu services" aria-labelledby="navbarDropdown">
                                            <Row>
                                                <Col lg="4" className="dropdown-single">
                                                    <ul>
                                                        <li><Link to="/internetofthings">Iot - Internet of Things</Link></li>
                                                        <li><Link to="/artificialintelligence">AI - Artificial Intelligence</Link></li>
                                                        <li><Link to="/unifiedcommunication">Unified Communication</Link></li>
                                                        <li><Link to="/cloudsolution">Cloud Solution</Link></li>
                                                        <li><Link to="/security">Security & Surveillance Solution</Link></li>
                                                        <li><Link to="/infrastructure">Infrastructure</Link></li>
                                                    </ul>
                                                </Col>
                                                <Col lg="4" className="dropdown-single">
                                                    <ul>
                                                        <li><Link to="/turnkey">Turnkey Projects</Link></li>
                                                        <li><Link to="/rollout">Rollout Projects</Link></li>
                                                        <li><Link to="/audiovideo">Audio - Video Solution</Link></li>
                                                        <li><Link to="/pagingsystem">Paging Systems Solution</Link></li>
                                                        <li><Link to="/consultation">Consultation</Link></li>
                                                        <li><Link to="/wirelessconnection">Wireless Connection</Link></li>
                                                    </ul>
                                                </Col>
                                                <Col lg="4" className="dropdown-single">
                                                    <ul>
                                                        <li><Link to="/telecommunication">Telecommunication Support</Link></li>
                                                        <li><Link to="/remotelearning">Digital Learning / Remote Learning</Link></li>
                                                        <li><Link to="/monitoringservice">Monitoring Service</Link></li>
                                                        <li><Link to="/renewableenergy">Renewable Energy</Link></li>
                                                        <li><Link to="/siptrunk">Session Initiation Protocol (SIP Trunk)</Link></li>
                                                        <li><Link to="/everything-as-a-service">Everything as a Service</Link></li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </li>
                                <li className= {solutionmenu === true ? "dropdown dd-respo": "dropdown"} onClick={SolutionMenu}>
                                    <Link to="#">Solutions by Industries <FontAwesome name="angle-down" /></Link>
                                    {solutionmenu === true && <div className="submenu-close2" onClick={SolutionMenu}>
                                            <FontAwesome name='close'/>
                                        </div> }
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <Row>
                                                <Col lg="5" className="dropdown-single">
                                                    <ul>
                                                        <li>
                                                            <Link to="/education">
                                                                <img src="Assets/Img/education.png"></img> 
                                                                <span>Education</span>
                                                            </Link>
                                                            </li>
                                                        <li>
                                                            <Link to="/retail">
                                                                <img src="Assets/Img/retail.png"></img> 
                                                                <span>Retail</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/financial">
                                                                <img src="Assets/Img/financial.png"></img> 
                                                                <span>Financial</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/healthcare">
                                                                <img src="Assets/Img/healthcare.png"></img> 
                                                                <span>Healthcare</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col lg="7" className="dropdown-single">
                                                    <ul>
                                                        <li>
                                                            <Link to="/manufacturing">
                                                                <img src="Assets/Img/industrial.png"></img> 
                                                                <span>Industrial/Manufacturing</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/transportation">
                                                                <img src="Assets/Img/transportation.png"></img> 
                                                                <span>Transportation/Automotive</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/hospitality">
                                                                <img src="Assets/Img/hospital.png"></img> 
                                                                <span>Hospitality</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/restaurants">
                                                                <img src="Assets/Img/restaurants.png"></img> 
                                                                <span>Restaurants/Service Industry</span>
                                                            </Link>
                                                        </li>
                                                       
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </li>
                                    <li className="contact-button"><Link to="#" onClick={toggle}>Get in Touch</Link></li>
                            </ul>
                            <div className="menu-icon">
                                {/* <FontAwesome name="bars" onClick={activateLasers} /> */}
                                <FontAwesome name={iconchange === true ? 'close' : 'bars'} onClick={activateLasers} />
                            </div>
                        </Col>
                        <Col lg="2" className="get-in-touch-button">
                            <Link to="#" onClick={toggle} className="btn-animate">Get in Touch</Link>
                        </Col>
                    </Navbar>
                </Row>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
    
    </Container>

        
    )
}

export default Header;