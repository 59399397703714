import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Careers(){
    const api_url = "https://api.anatechnology.net/public/api/";
    useEffect(()=>{

        getCareersData();
    },[]);

    const [careerdata, setCareerData] = useState([]);
    function getCareersData(){
        fetch(api_url+'getActiveCareers')
            .then((response) => response.json())
            .then((responseJson) => {
                setCareerData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Careers</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>Careers</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="careers-section">
                
                        <Row>
                        <Col lg="12" className="single-jobs section-head">
                                <h2>Job Title</h2>
                                <h4>Job Type</h4>
                                <h4>Email</h4>
                                {/* <a href="/internetofthings" className="btn-animate">Apply Now</a> */}
                            </Col>
                        {
                            careerdata.length > 0 ? careerdata.map((response,i)=>{
                                // return   <div key={i}>
                                //         <h3>{response.description}</h3>
                                //         <Link to="/contact" className="btn-animate">Contact us</Link>
                                //     </div>
                                //         }) : null
                                return <Col lg="12" key={i} className="single-jobs section-head">
                                    <h2>{response.job_title}</h2>
                                        <h4>{response.job_type}</h4>
                                    <h4>Send your Resume at <strong>{response.email}</strong></h4>
                                </Col> }) : null
                        }

                            {/* <Col lg="12" className="single-jobs section-head">
                                <h2>Job Title</h2>
                                <h4>Job Type</h4>
                                <h4>Send your Resume at <strong>mail@example.com</strong></h4>

                            </Col>
                            <Col lg="12" className="single-jobs section-head">
                                <h2>Job Title</h2>
                                <h4>Job Type</h4>
                                <h4>Send your Resume at <strong>mail@example.com</strong></h4>

                            </Col>
                            <Col lg="12" className="single-jobs section-head">
                                <h2>Job Title</h2>
                                <h4>Job Type</h4>
                                <h4>Send your Resume at <strong>mail@example.com</strong></h4>
                            </Col>
                            <Col lg="12" className="single-jobs section-head">
                                <h2>Job Title</h2>
                                <h4>Job Type</h4>
                                <h4>Send your Resume at <strong>mail@example.com</strong></h4>
                            </Col> */}
                            
                        </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Careers;