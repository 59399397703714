import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Terms(){
    const api_url = "https://api.anatechnology.net/public/api/";
    useEffect(()=>{
        getTermsConditionData();
    },[]);

    const [getTermsCondition, setTermsCondition,] = useState([]);
    function getTermsConditionData(){
        fetch(api_url+'getTermsConditionAllActive')
            .then((response) => response.json())
            .then((responseJson) => {
                setTermsCondition(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Terms & Conditions</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>Terms & Conditions</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="terms-section">
                <Row>
                    <Col lg="12" className="terms-col section-head">
                        <h3>Terms & Conditions</h3>
                        {
                            getTermsCondition.length > 0 ? getTermsCondition.map((response,i)=>{
                                return <div key={"single-term"+i} className="single-term">
                                    <h4 key={"title"+i}>{response.title}</h4>
                                    <p key={"content"+i}>{response.content}</p>
                                </div>
                            }) : null
                         }
                        {/* <div className="single-term">
                            <p>This website is operated by Ana. Throughout the site, the terms “we,” “us,” and “our” refer to Ana. By visiting our site and/ or using our products, you engage in our “Service” and agree to be bound by the following terms and conditions, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including browsers. </p>
                            <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                            <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. </p>
                            <p>We reserve the right to update, change, or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 1 – USING OUR PRODUCTS</h4>
                            <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                            <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
                            <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
                            <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 2 - GENERAL CONDITIONS</h4>
                            <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                            <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
                            <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
                            <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h4>
                            <p>We are not responsible if information made available on this site is not accurate, complete, or current.</p>
                            <p>The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
                            <p>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h4>
                            <p>Prices for our products are subject to change without notice.</p>
                            <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
                            <p>We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 5 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h4>
                            <p>We reserve the right to refuse any request you place with us. We may, in our sole discretion, limit or cancel order requested per person. These restrictions may include product requests placed by or under the same customer account, the same credit card, and/or requests that use the same billing and/or shipping address.</p>
                            <p>In the event that we make a change to or cancel a request, we may attempt to notify you by contacting the email and/or billing address/phone number provided at the time the request was made. We reserve the right to limit or prohibit requests that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.</p>
                            <p>You agree to provide current, complete, and accurate account information for all the orders requested at our website. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 6 - THIRD-PARTY LINKS</h4>
                            <p>Certain content and products available via our Service may include materials from third-parties.</p>
                            <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy, and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials and services of third parties.</p>
                            <p>We are not liable for any harm or damages related to the use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party courses should be directed to the third party. </p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 7 - ERRORS, INACCURACIES, AND OMISSIONS</h4>
                            <p>Occasionally, there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, transit times or discounts. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information or cancel your order request if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your request).</p>
                            <p>We undertake no obligation to update, amend, or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 8 - CHANGES TO TERMS OF SERVICE</h4>
                            <p>You can review the most current version of the Terms of Service at any time on this page.</p>
                            <p>We reserve the right, at our sole discretion, to update, change, or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                        </div>
                        <div className="single-term">
                            <h4>SECTION 9 - CONTACT INFORMATION</h4>
                            <p>Questions about the Terms of Service should be sent to us at <a href="mailto:hello@anatelecom.net">hello@anatelecom.net.</a></p>
                        </div>
                        <div className="single-term">
                            <h4>Section 10 – VOIP Services</h4>
                            <p>When customers call Ana to request activation of their VoIP service, Ana’s representative will notify them of the documents in the starter kit and how the VoIP emergency 9-1-1 services differ from traditional 9-1-1 services.</p>
                            <p>Once their VoIP service is ready to be activated, customers will, through the Internet, log onto their account management site to activate their 9-1-1 service. </p>
                            <p>As part of the activation process, text similar to the printed 9-1-1 documents will be presented to the customers and the customers will be presented with the following in English/French (as appropriate) and their selections will be captured:</p>
                            <p><strong>I agree that I have read and understand the 9-1-1 service limitations described above.</strong></p>
                            <p>"I Agree" </p>
                            <p>"I Do Not Agree"</p>
                        </div> */}
                    </Col>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Terms;