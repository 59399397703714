import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function Security(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Security & Surveillance Solution</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text security-service">
                        
                            <h2>Security & Surveillance Solution</h2>
                            <p>Secured for life</p>        
                            <ul>
                                <li>About 60% of burglaries are forcible entry. Security system with surveillance cameras can discourage these types of crimes</li>
                            </ul>
                            <p>From analog to IP Video Surveillance</p>
                            <p>Monitor, protect and automate your business – all in ONE app</p>
                            <ul>
                                <li>Interactive security</li>
                                <li>Smarter locks</li>
                                <li>Multi-sensor</li>
                                <li>Video analytics</li>
                            </ul>
                            <p>Connect to any of your smart devices for easy control</p>
                            <p>Live or recorded – you’re in the loop</p>
                            <p>All-around premise security and surveillance</p>
                            <ul>
                                <li>Video intercom</li>
                                <li>IP cameras</li>
                                <li>CCTV Cameras</li>
                            </ul>
                            <p>Reducing risk of breach and increasing peace of mind</p>
                            <p>Schools, Small-Medium Businesses, Residential areas, Apartment</p>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                               <img src="./Assets/Img/security.png"></img>
                            {/* <img src="./Assets/Img/internet-img.png"></img> */}
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default Security;