import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';
import FontAwesome from 'react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';

// import faStyles from 'font-awesome/css/font-awesome.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link, useRouteMatch, useParams } from 'react-router-dom';
import Slider from "react-slick";
import Ticker from '../Components/Ticker';
import '../particles.css';
import ModalComponent from '../Components/ModalComponent';


import {
    Container,
    Row,
    Col,
    Input,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
  } from "reactstrap";

  import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
  import 'react-tabs/style/react-tabs.css';

function Home(){
    const api_url = "https://api.anatechnology.net/public/api/";

    useEffect(()=>{

        getBannerData();
        getPartnersData();
        getCustomersData();
        getTestimonialsData();
        getservicesData();
        getSiteInfoData();
        getSiteAnalyticsData();
    },[]);

    const [getSiteAnalytics, setSiteAnalytics] = useState([]);
    function getSiteAnalyticsData(){
        fetch(api_url+'analytics')
            .then((response) => response.json())
            .then((responseJson) => {
                setSiteAnalytics(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const [getSiteInfo, setSiteInfo] = useState([]);
    function getSiteInfoData(){
        fetch(api_url+'general-information')
            .then((response) => response.json())
            .then((responseJson) => {
                setSiteInfo(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const [data, setData] = useState([]);
    function getBannerData(){
        fetch(api_url+'banners')
            .then((response) => response.json())
            .then((responseJson) => {
                setData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const [partnersData, setpartnersData] = useState([]);
    function getPartnersData(){
        fetch(api_url+'partners')
            .then((response) => response.json())
            .then((responseJson) => {
                setpartnersData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const [customersData, setcustomersData] = useState([]);
    function getCustomersData(){
        fetch(api_url+'customers')
            .then((response) => response.json())
            .then((responseJson) => {
                setcustomersData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const [testimonialsData, settestimonialsData] = useState([]);
    function getTestimonialsData(){
        fetch(api_url+'testimonials')
            .then((response) => response.json())
            .then((responseJson) => {
                settestimonialsData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const [serviceData, setserviceData] = useState([]);
    function getservicesData(){
        fetch(api_url+'categories')
            .then((response) => response.json())
            .then((responseJson) => {
                setserviceData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }



    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}
          />
        );
      }

      const [modal, setModal] = useState(false);

      function toggle(){
        setModal(!modal);
    }

      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
          />
        );
      }

    const homeSlider = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
            }
          },
          {
            breakpoint: 768,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000,
            }
          }
          ]
      };

      const partnerSlider = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        arrows:true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 4,
                arrows:true,
                autoplay: true,
                autoplaySpeed: 12000,
                slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 3,
                arrows:true,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToScroll: 1,
            }
          }
          ]
      };

      const testimonialSlider = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 10000,
                slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                arrows:false,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToScroll: 1,
            }
          }
          ]
      };

      const customerSlider = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        rows:3,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 4,
                rows:3,
                arrows:false,
                autoplay: false,
                slidesToScroll: 3,
            }
          },
          {
            breakpoint: 768,
            settings: {
                dots: true,
                infinite: true,
                slidesToShow: 3,
                rows:3,
                arrows:false,
                slidesToScroll: 3,
                autoplay: true,
                autoplaySpeed: 3000,
            }
          },
          {
            breakpoint: 568,
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 2,
                rows:3,
                arrows:true,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToScroll: 1,
            }
          },
          {
            breakpoint: 425,
            settings: {
                dots: false,
                infinite: true,
                slidesToShow: 1,
                rows:3,
                arrows:true,
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToScroll: 1,
            }
          }
          ]
      };

    return(
        <Container className="wrapper" fluid>
            <Header/>
             
            <Container className="slider" id="ani-banner" fluid>
            <div className="particles-wrap">
                <div className='particles'></div>
                <div className='particles2'></div>
                <div className='particles3'></div>
            </div>

            <div className="boxes">
                <div className="box mid"></div>
                <div className="box small"></div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box mid"></div>
                <div className="box mid"></div>
                <div className="box mid"></div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box mid"></div>
                <div className="box"></div>
                <div className="box mid"></div>
                <div className="box small"></div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box small"></div>
                <div className="box mid"></div>
            </div>

            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>

            <img className="img-footer" src="Assets/Img/img-bottom.png" alt=""></img>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <Slider className="home-slider" {...homeSlider} >
                                    {
                                         data.length > 0 ? data.map((response,i)=>{
                                          return   <div key={i}>
                                        <h3>{response.description}</h3>
                                        <Link to="/contact" className="btn-animate">Contact us</Link>
                                    </div>
                                        }) : null
                                    }
                                    {/* <div>
                                        <h3>Inclusion Of Internet Connectivity <br/>to the everyday devices</h3>
                                        <Link to="/contact" className="btn-animate">Contact us</Link>
                                    </div>
                                    <div>
                                        <h3>Inclusion Of Internet Connectivity to the everyday devices</h3>
                                        <Link to="/contact" className="btn-animate">Contact</Link>
                                    </div>
                                    <div>
                                        <h3>Inclusion Of Internet Connectivity to the everyday devices</h3>
                                        <Link to="/contact" className="btn-animate">Contact</Link>
                                    </div> */}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="home-s02">
                <Row>
                    
                    <Col lg="4" md="6" sm="6" className="single-s02" >
                        <Link to="/internetofthings">
                            <div className="icon">
                                <img src="Assets/Img/s02-img1.svg"></img>
                            </div>
                            <h2>Internet of Things</h2>
                            <p>Whether you’re planning a new IoT initiative or want to upgrade an existing one, we have you covered.</p>
                        </Link>
                    </Col>
                   
                    <Col lg="4" md="6" sm="6" className="single-s02">
                        <Link to="/artificialintelligence">
                            <div className="icon">
                                <img src="Assets/Img/s02-img2.svg"></img>
                            </div>
                            <h2>Artificial Intelligence</h2>
                            <p>We use the power of AI, helping you achieve your business objectives efficiently.</p>
                    </Link>
                    </Col>
                    <Col lg="4" md="6" sm="6" className="single-s02">
                        <Link to="/audiovideo">
                            <div className="icon">
                                <img src="Assets/Img/s02-img3.svg"></img>
                            </div>
                            <h2>Audio – Video solution</h2>
                            <p>As technology providers, we offer audio-visual solutions, upgrading the way you connect globally.</p>
                        </Link>
                    </Col>
                    <Col lg="4" md="6" sm="6" className="single-s02">
                        <Link to="/cloudsolution">
                            <div className="icon">
                                <img src="Assets/Img/s02-img4.svg"></img>
                            </div>
                            <h2>Cloud Solution</h2>
                            <p>Strengthen remote workforce and take unified communication to the next level – all in one place.</p>
                        </Link>
                    </Col>
                    <Col lg="4" md="6" sm="6" className="single-s02">
                        <Link to="/turnkey">
                            <div className="icon">
                                <img src="Assets/Img/s02-img5.svg"></img>
                            </div>
                            <h2>Turnkey Projects</h2>
                            <p>Whether you’re a startup or a midlevel enterprise, we offer comprehensive coordination, consultation, planning, and executing services.</p>
                        </Link>
                    </Col>
                    <Col lg="4" md="6" sm="6" className="single-s02">
                        <Link to="/rollout">
                            <div className="icon">
                                <img src="Assets/Img/s02-img6.svg"></img>
                            </div>
                            <h2>Rollout projects</h2>
                            <p>Maximize the lifetime value of your applications and keep your business up-to-date with us at your back.</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
            
            <Container className="partners">
                <Row>
                    <Col lg="12" md="12" className="section-head">
                        <h2>Partners</h2>
                    </Col>
                    <Col lg="12" md="12">
                        <Slider className="partner-slider" {...partnerSlider} >
                           {
                                         partnersData.length > 0 ? partnersData.map((response,i)=>{
                                          return  <img key={i} src={response.image}></img>
                                        }) : null
                            }

                            {/* <img src="Assets/Img/partner-logo1.png"></img>
                            <img src="Assets/Img/partner-logo2.png"></img>
                            <img src="Assets/Img/partner-logo3.png"></img>
                            <img src="Assets/Img/partner-logo4.png"></img>
                            <img src="Assets/Img/partner-logo1.png"></img>
                            <img src="Assets/Img/partner-logo2.png"></img>
                            <img src="Assets/Img/partner-logo3.png"></img>
                            <img src="Assets/Img/partner-logo4.png"></img> */}
                        </Slider>
                    </Col>
                </Row>
            </Container>

            <Container className="why-ana" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="7" md="12" className="why-ana-img">
                                <img src="Assets/Img/why-ana-img.png"></img>
                            </Col>
                            <Col lg="5" md="12" className="why-ana-text section-head">
                                <h2>Why <span>ana Technology</span>?</h2>
                                <p>As digital technology providers, we cater to several industry types. Unlike others who might restrict themselves to some tech services, we offer a wide range of innovative solutions and services, giving you the ability to advance in the digital realms.</p>
                                <p>In this way, we’re creating positive impacts for our partners and clients alike.</p>
                                <Link to="/about" className="btn-animate">Read More</Link>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="lets-work" fluid>
            <div className="particles-wrap">
                <div className='particles'></div>
                <div className='particles2'></div>
                <div className='particles3'></div>
            </div>

            <div className="boxes">
                <div className="box mid"></div>
                <div className="box small"></div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box mid"></div>
                <div className="box mid"></div>
                <div className="box mid"></div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box mid"></div>
                <div className="box"></div>
                <div className="box mid"></div>
                <div className="box small"></div>
                <div className="box"></div>
                
               
                
            </div>

            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>

            <img className="img-footer" src="Assets/Img/let-work-overlay.png" alt=""></img>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="5" md="12" className="lets-work-text section-head">
                                <h2>Let's Work Together</h2>
                                <p>Interested in working with us? We’d love to have a talented individual like you on our team. </p>

                                <p>
                                {
                                    getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                                        let str = "tel:"+response.phone;
                                        return  <a href={str}>{response.head_toll_free}</a>

                                    }) : null
                                }

                                    {/* <a href="tel:18444767757">1 844 476 7757</a> */}
                                    <br/>
                                    {
                                    getSiteInfo.length > 0 ? getSiteInfo.map((response,i)=>{
                                        let email = "mailto:"+response.email;
                                        return  <a href={email} className="mail">{response.email}</a>

                                    }) : null
                                }

                                    {/* <a href="mailto:hello@anatelecom.net" className="mail">hello@anatelecom.net</a> */}

                                    </p>
                                <Link to="#" onClick={toggle} className="btn-animate">Get in Touch</Link>
                            </Col>
                            <Col lg="7" md="12" className="lets-work-img">
                                <img src="Assets/Img/work-together-img.png"></img>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
            <Container className="counter">
                <Row>
                     
                                

                    <Col lg="3" md="6" sm="6" xs="12" className="singleCounter">
                        <ScrollAnimation animateIn="slideInLeft" animateOut="slideInLeft">
                        {
                                    getSiteAnalytics.length > 0 ? getSiteAnalytics.map((response,i)=>{
                                       return  <>
                                        <Ticker className="count" end={response.heading_one_count}/>
                                        <p>{response.heading_one}</p>
                                       </>
                                       

                                    }) : null
                        }
                           
                           
                        </ScrollAnimation>
                    </Col>
                    <Col lg="3" md="6" sm="6" xs="12" className="singleCounter">
                        <ScrollAnimation animateIn="slideInDown" animateOut="slideInDown">
                        {
                                    getSiteAnalytics.length > 0 ? getSiteAnalytics.map((response,i)=>{
                                       return   <>
                                       <Ticker className="count" end={response.heading_two_count}/>
                                       <p>{response.heading_two}</p>
                                       </>

                                    }) : null
                        }
                            
                        </ScrollAnimation>
                    </Col>
                    <Col lg="3" md="6" sm="6" xs="12" className="singleCounter">
                        <ScrollAnimation animateIn="slideInUp" animateOut="slideInUp">
                        {
                                    getSiteAnalytics.length > 0 ? getSiteAnalytics.map((response,i)=>{
                                        return   <>
                                       <Ticker className="count" end={response.heading_three_count}/>
                                       <p>{response.heading_three}</p>
                                       </>

                                    }) : null
                        }
                           
                        </ScrollAnimation>
                    </Col>
                    <Col lg="3" md="6" sm="6" xs="12" className="singleCounter">
                        <ScrollAnimation animateIn="slideInRight" animateOut="slideInRight">
                        {
                                    getSiteAnalytics.length > 0 ? getSiteAnalytics.map((response,i)=>{
                                        return   <>
                                        <Ticker className="count" end={response.heading_four_count}/>
                                        <p>{response.heading_four}</p>
                                        </>
 
                                     }) : null
                        }
                            
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
            <Container className="promotional-services">
                <Row>
                    <Col lg="6" className="promotional-left">
                        <ScrollAnimation animateIn="slideInDown" animateOut="slideInDown">
                        <div className="single-promo">
                            <img src="Assets/Img/single-promo1.png"></img>
                            <div className="single-promo-text">
                                <h2>Education</h2>
                                <p>Cloud solutions, virtual classroom solutions, and interactive classrooms using the concept of digital learning.</p>
                            </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInLeft" animateOut="slideInLeft">
                        <div className="single-promo">
                            <img src="Assets/Img/single-promo2.png"></img>
                            <div className="single-promo-text">
                                <h2>Finance</h2>
                                <p>Surveillance and security solutions as well as digital training for employees and smart WIFI installation for high-speed internet.</p>
                            </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInUp" animateOut="slideInUp">
                        <div className="single-promo">
                            <img src="Assets/Img/single-promo3.png"></img>
                            <div className="single-promo-text">
                                <h2>Healthcare</h2>
                                <p>Emergency paging system solutions, virtual clinical models, and smart medical devices to ease the treatment process.</p>
                            </div>
                        </div>
                        </ScrollAnimation>
                    </Col>
                    <Col lg="6" className="section-head promotional-right">
                        <h2>Industries We Cater to</h2>
                        <p>At <span>ana Technology</span>, we pride ourselves on being at the forefront of a wide array of industries.</p>
                        <img src="Assets/Img/promotional-img.png"></img>
                    </Col>
                </Row>
            </Container>

            <Container className="projects" fluid>
                <Row>
                    <Container>
                <Row>
                    <Col lg="12" className="section-head">
                        <h2>Previous Projects</h2>
                    </Col>
                    <Col lg="12">
                        <Tabs className="project-tabs">
                            <TabList className="project-tablist">
                            <Tab>All</Tab>
                                {
                                    //serviceData
                                    serviceData.length > 0 ? serviceData.map((response,i)=>{
                                        //   return  <img key={i} src={response.image}></img>
                                        return <Tab>{response.shortname}</Tab>
                                        }) : null
                                }
                                {/* <Tab>All</Tab>
                                <Tab>IOT</Tab>
                                <Tab>AI</Tab>
                                <Tab>VIsual Solutions</Tab> */}

                            </TabList>
                            <TabPanel className="project-tabcontent">
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img4.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Whether you’re planning a new IoT initiative or want to upgrade an existing one, we have you covered.</p>
                                        <a href="https://anatechnology.net/internetofthings" className="btn-animate">Read More</a>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img1.png"></img>
                                    <div className="single-project-content">
                                        <h2>Artificial Intelligence</h2>
                                        <p>We use the power of AI, helping you achieve your business objectives efficiently.</p>
                                        <a href="https://anatechnology.net/artificialintelligence" className="btn-animate">Read More</a>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img2.png"></img>
                                    <div className="single-project-content">
                                        <h2>Audio-Video Solution</h2>
                                        <p>As technology providers, we offer audio-visual solutions, upgrading the way you connect globally. </p>
                                        <a href="https://anatechnology.net/audiovideo" className="btn-animate">Read More</a>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img3.png"></img>
                                    <div className="single-project-content">
                                        <h2>Cloud Solution</h2>
                                        <p>Strengthen remote workforce and take unified communication to the next level – all in one place.</p>
                                        <a href="https://anatechnology.net/cloudsolution" className="btn-animate">Read More</a>
                                    </div>
                                </div>
                            </TabPanel>
                             
                             {
                                  serviceData.length > 0 ? serviceData.map((response,i)=>{
                                    //console.log(response.projects.length);

                                    return  <TabPanel className="project-tabcontent"> 
                                    {
                                        
                                        response.projects.length > 0 ? response.projects.map((pro,ii)=>{
                                        return <div className="single-project-tab">
                                                    <img src={pro.image}></img>
                                            <div className="single-project-content">
                                                <h2>{pro.name}</h2>
                                                <p>{pro.description}</p>
                                                <a href={pro.url} className="btn-animate">Read More</a>
                                            </div>
                                        </div>
                                        }): null
                                    }
                                </TabPanel>
                                    }) : null
                             }   

                            {/* <TabPanel className="project-tabcontent">
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img1.png"></img>
                                    <div className="single-project-content">
                                        <h2>Artificial Intelligence</h2>
                                        <p>We use the power of AI, helping you achieve your business objectives efficiently.</p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img4.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Whether you’re planning a new IoT initiative or want to upgrade an existing one, we have you covered.</p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img3.png"></img>
                                    <div className="single-project-content">
                                        <h2>Cloud Solution</h2>
                                        <p>Strengthen remote workforce and take unified communication to the next level – all in one place.</p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img2.png"></img>
                                    <div className="single-project-content">
                                        <h2>Audio-Video Solution</h2>
                                        <p>As technology providers, we offer audio-visual solutions, upgrading the way you connect globally. </p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel className="project-tabcontent">
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img4.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Whether you’re planning a new IoT initiative or want to upgrade an existing one, we have you covered.</p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img1.png"></img>
                                    <div className="single-project-content">
                                        <h2>Artificial Intelligence</h2>
                                        <p>We use the power of AI, helping you achieve your business objectives efficiently.</p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img2.png"></img>
                                    <div className="single-project-content">
                                        <h2>Audio-Video Solution</h2>
                                        <p>As technology providers, we offer audio-visual solutions, upgrading the way you connect globally. </p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img3.png"></img>
                                    <div className="single-project-content">
                                        <h2>Cloud Solution</h2>
                                        <p>Strengthen remote workforce and take unified communication to the next level – all in one place.</p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel className="project-tabcontent">
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img1.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img2.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img3.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                                <div className="single-project-tab">
                                    <img src="Assets/Img/tab-img4.png"></img>
                                    <div className="single-project-content">
                                        <h2>Internet of things</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        <Link to="/" className="btn-animate">Button</Link>
                                    </div>
                                </div>
                            </TabPanel>
                         */}
                        
                        </Tabs>
                        
                    </Col>
                </Row>
            </Container>
        </Row>
    </Container>

            <Container className="testimonials">
                <Row>
                    <Col lg="12" md="12" className="section-head">
                        <h2>What Clients are Saying</h2>
                    </Col>
                    <Col lg="12" md="12">
                        
                        <Slider className="testimonial-slider" {...testimonialSlider} >

                        {
                            testimonialsData.length > 0 ? testimonialsData.map((response,i)=>{
                                        //   return  <img key={i} src={response.image}></img>
                                        return <div key={i}>
                                        <img src={response.image} alt="Testimonial Image"></img>
                                        <div className="testi-content">
                                            <p>{response.description}</p>
                                            <strong>- {response.name}</strong>
                                            <span>{response.name}</span>
                                        </div>
                                    </div>

                                        }) : null
                            }

                            {/* <div>
                                <img src="Assets/Img/testi-img.png"></img>
                                <div className="testi-content">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <strong>- Lorem Name</strong>
                                    <span>Lorem  Ipsum</span>
                                </div>
                            </div>
                            <div>
                                <img src="Assets/Img/testi-img.png"></img>
                                <div className="testi-content">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <strong>- Lorem Name</strong>
                                    <span>Lorem  Ipsum</span>
                                </div>
                            </div>
                            <div>
                                <img src="Assets/Img/testi-img.png"></img>
                                <div className="testi-content">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <strong>- Lorem Name</strong>
                                    <span>Lorem  Ipsum</span>
                                </div>
                            </div>
                            <div>
                                <img src="Assets/Img/testi-img.png"></img>
                                <div className="testi-content">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <strong>- Lorem Name</strong>
                                    <span>Lorem  Ipsum</span>
                                </div>
                            </div>
                            <div>
                                <img src="Assets/Img/testi-img.png"></img>
                                <div className="testi-content">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <strong>- Lorem Name</strong>
                                    <span>Lorem  Ipsum</span>
                                </div>
                            </div>
                            <div>
                                <img src="Assets/Img/testi-img.png"></img>
                                <div className="testi-content">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <strong>- Lorem Name</strong>
                                    <span>Lorem  Ipsum</span>
                                </div>
                            </div> */}
                        </Slider>
                        
                    </Col>
                </Row>
            </Container>

            <Container className="customers">
                <Row>
                    <Col lg="12" md="12" className="section-head">
                        <h2>Customers</h2>
                    </Col>
                    <Col lg="12" md="12">
                        <Slider className="customer-slider" {...customerSlider} >
                           {
                                customersData.length > 0 ? customersData.map((response,i)=>{
                                          return   <div key={i}> <img  src={response.image}></img> </div> 
                                        }) : null
                            }

                            {/* <div>
                                <img src="Assets/Img/customer1.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer2.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer3.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer4.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer5.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer6.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer7.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer8.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer9.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer10.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer11.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer12.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer1.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer2.png"></img>
                            </div>
                            <div>
                                <img src="Assets/Img/customer3.png"></img>
                            </div> */}
                        </Slider>
                    </Col>
                </Row>
            </Container>
            <Footer/>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
        </Container>
        
    )
}

export default Home;