import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const loader = document.querySelector('.preloader');
const fixBody = document.querySelector('.fixBody');
const addLogo = document.querySelector('.addLogo');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');
const removeClass = () => fixBody.classList.remove('fixBody');
const addClass = () => addLogo.classList.add('addLogo2')

const hideLoader = () => loader.classList.add('loader--hide');
setTimeout(() => 
ReactDOM.render(
  <React.StrictMode>
    <App
    hideLoader={hideLoader}
    removeClass={removeClass}
    addClass={addClass}
    showLoader={showLoader} 
     />
  </React.StrictMode>,
  document.getElementById('root')
)
,20);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
