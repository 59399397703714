import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function EverythingOther(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Everything As A Service </span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text sip-trunk">
                            <div class="single-sip">
                                <h4>Data as a Service (DaaS)</h4>
                                <p>It is enabled by software as a service. DaaS builds in a concept that its data product can be provided to the user on demand, no matter where. DaaS outsource most data storage, integration, processing and/or analytics service using network connection or cloud.</p>
                                <p>Generic cloud computing services were not initially designed for handling huge data before. Processing large data sets via the network was difficult in the early days of cloud computing due to limited bandwidth.</p>
                                <p>Due to low-cost cloud storage and expansion of bandwidth, combined with cloud-based platforms, DaaS is now beggini9ng to be as practical and beneficial as SaaS</p>
                                <p>Benefits:</p>
                                <ul>
                                    <li>Minimal set-up time</li>
                                    <li>Improved functionality</li>
                                    <li>Greater flexibility</li>
                                    <li>Cost savings</li>
                                    <li>Automated maintenance </li>
                                    <li>Smaller staff requirements</li>
                                </ul>
                            </div>
                            <div class="single-sip">
                                <h4>Hardware as a Service (HaaS)</h4>
                                <p>A procurement model that is similar to leasing or licensing in which hardware that belongs to a managed service provider is installed at a customer’s site and a service level agreement defines the responsibilities of both parties.</p>
                                <p>A cost-effective way for small or mid-sized business to provide employees with state of the art hardware in a cost-effective manner.</p>
                                <p>A pay-as-you-go model for accesing a provider’s infrastructure and CPU power grid</p>
                                <p>Benefits:</p> 
                                <ul>
                                    <li>Reduce upfront capital cost</li>
                                    <li>Updated equipment depending on the contract</li>
                                    <li>Built-in maintenance and troubleshooting cost </li>
                                </ul>
                            </div>
                            <div class="single-sip">
                                <h4>Software as a Service (SaaS) </h4>
                                <p>Also knows as cloud-based software, delivering applications to the end-users over the network rather than having them run applications locally on their devices.</p>
                                <p>SaaS deployment don’t require extensive hardware, which allows buyers to outsource most of the IT responsibilities</p>
                                <ul>
                                    <li>SaaS systems are typically paid within a subscription model. A budget-friendly option for businesses with smaller budgets.</li>
                                    <li>Offers exact same experience as on-premise installation</li>
                                    <li>Monthly subscription typically includes software license, support and most other fees</li>
                                </ul>
                            </div>
                            <div class="single-sip">
                                <h4>Unified Communication as a Service (UCaaS)</h4>
                                <p>Same concept as Unified Communication which offers similar features, such as voice and telephony, video and audio conferencing, real time messaging and presence but UCaaS is hosted by a provider.</p>
                                <p>Avoid initial expenses often associated with setting up an advanced communication strategy</p>
                                <h4>Contact Centre as a Service (CCaaS)</h4>
                                <p>A software deployment model that enables companies to only purchase the technology they need and is commonly operated by a vendor to reduce IT, integration, and support costs.</p>
                                <p>Most commonly deployed as a cloud-based customer solution in contact centers.</p>
                                <p>Ideal option for many contact centers, offering scalability as operational needs change.</p>
                                <p>Benefits:</p>
                                <ul>
                                    <li>Significant cost savings</li>
                                    <li>A better experience for your customer</li>
                                    <li>Scalability, reliability and availability</li>
                                </ul>
                            </div>
                            <div class="single-sip">
                                <h4>Capital Expenditure (CAPEX)</h4>
                                <p>Capital expenditure is when a business pays out money to acquire, upgrade, and maintain physical assets. Represented as an investment in the business</p>
                                <p>Depreciates in value</p>
                                <p>Examples:</p>
                                <ul>
                                    <li>Business equipment</li>
                                    <li>On-premise PBX</li>
                                    <li>Building or space</li>
                                    <li>Property</li>
                                    <li>Contact Centre</li>
                                    <li>Hardware </li>
                                </ul>
                            </div>
                            <div class="single-sip">
                                <h4>Operating Expenses (OPEX) </h4>
                                <p>Ongoing cost incurred by businesses through its normal business operations.</p>
                                <p>OPEX are short-term expenses and are typically used up in accounting period.</p>
                                <p>Examples:</p>
                                <ul>
                                    <li>Rent & Utilities</li>
                                    <li>Overhead cost</li>
                                    <li>Property Tax</li>
                                    <li>Business travel</li>
                                    <li>Research & development</li>
                                </ul>
                                <p>Other services that can be considered as OPEX are</p>
                                <ul>
                                    <li>Software as a Service</li>
                                    <li>Data as a Service</li>
                                    <li>Hardware as a Service</li>
                                    <li>Unified Communication as a Service</li>
                                    <li>Contact Centre as a Service</li>
                                </ul>
                                <p>Anything as a service basically provide endpoints for customers to interface with which are usually API driven, but are commonly controlled via a web console in a user’s browser.</p>
                                <p>Such services can be look at as a “pay-as-you-go” subscription-licensing model that allows the company to use the vendor’s software.</p>
                            </div>
                            <div class="single-sip why-choose">
                                <h4>Why choose everything as a service (XaaS)?</h4>
                                <p>XaaS encompasses a vast number of products, tools, and technologies that vendors now deliver as-a-service to multiple sectors over a network by way of licensing-and-delivery rather than providing them on-premise or on-site.</p>
                                <p>On-premise or on-site typically meant investing on computer programs and hardware which takes up space on premises and must be backed up, maintained and upgraded regularly.</p>
                                <p>Companies can now make use of cloud-based infrastructure for practically all their technology requirements. Freeing up corporate resources to focus on core business.</p>
                                <ul>
                                    <li>Speeding new apps and business processes – quickly adapt to changing market conditions with new apps or solutions</li>
                                    <li>Streamline operations and free up resources for innovation</li>
                                    <li>Scalability - you can scale up or down your storage depending on usage</li>
                                    <li>Improving expense model – businesses can cut cost by purchasing services from providers on a subscription basis. From the hardware viewpoint, they not only avoid having to buy expensive server and storage equipment, but they no longer need to pay for space to keep it in.</li>
                                    <li>Stronger cybersecurity – to eliminate hackers attacking their system</li>
                                    <li>Better backup and disaster recovery planning</li>
                                </ul>
                                <p>Any company planning to grow or expand will benefit from having access to top-level IT expertise and the increased opportunity offered by cloud services.</p>
                            </div>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/Data-01.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default EverythingOther;