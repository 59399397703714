import '../App.css';
import '../Style.css';

import 'bootstrap/dist/css/bootstrap.css';
import logo from '../logo.png';
import React, { Component, useState, useEffect } from "react";


import {
    Container,
    Row,
    Col,
    Button,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter
  } from "reactstrap";



function ModalComponent(props){

    
        const {
          buttonLabel,
          className
        } = props;

        useEffect(()=>{
            getservicesData();
        },[]);

        const api_url = "https://api.anatechnology.net/public/api/";
        const [formSubmitMesage, setformSubmitMesage] = useState('');
        const [serviceData, setserviceData] = useState([]);
        function getservicesData(){
        fetch(api_url+'categories')
            .then((response) => response.json())
            .then((responseJson) => {
                setserviceData(responseJson.data);
            })
            .catch((error) => {
              console.error(error);
            });
    }

      
        const [modal, setModal] = useState(false);

        const [loader, setLoader] = useState(false);

        // const toggle = () => setModal(!modal);
        function toggle(){
            setModal(!modal);
        }

        function emptyFields(){
            setFullName('');
            setEmail('');
            setService('');
            setPhone('');
            setMessage('');
            setInterval(
                () =>
                setformSubmitMesage(''),
                10000
              );
        }
       
        const [userFullName, setFullName] = useState('');
        const [userEmail, setEmail] = useState('');
        const [userPhone, setPhone] = useState('');
        const [userService, setService] = useState('');
        const [userMessage, setMessage] = useState('');

        const [userFullNameError, setFullNameError] = useState(false);
        const [userEmailError, setEmailError] = useState(false);
        const [userPhoneError, setPhoneError] = useState(false);
        const [userServiceError, setServiceError] = useState(false);
        const [userMessageError, setMessageError] = useState(false);

        function validate(){
           //console.log("In");
            let status = true;
            if(userFullName === ''){
                setFullNameError(true);
                status = false;
            }

            if(userEmail === ''){
                setEmailError(true);
                status = false;
            }
            if(userService === ''){
                setServiceError(true);
                status = false;
            }
            if(userPhone === ''){
                setPhoneError(true);
                status = false;
            }
            if(userMessage === ''){
                setMessageError(true);
                status = false;
            }
        return status;

        }

        function handleSubmit  (event) {
           
            event.preventDefault();
            if(validate()) {
                setLoader(true);
           
            console.log("userFullName>>",userFullName);
            console.log("userEmail>>",userEmail);
            console.log("userService>>",userService);
            console.log("userMessage>>",userMessage);

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: userFullName, email:userEmail, service:userService, message:userMessage, formname:'header-form',phone:userPhone})
            };

            fetch(api_url+'contact', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.success == true){
                setformSubmitMesage('Form Submitted Successfully');
                setLoader(false);
                emptyFields();
                //props.toggle();
                // this.handleShow();
            } else {
                setLoader(false);
                setformSubmitMesage('Fill all fields');
            }
        });
    }
        }

    return(
       
            
    <Modal isOpen={props.modal} fade={true} toggle={() => props.toggle()} centered={true} className="modal-lg modalTransition">
        <ModalBody>
       
            <button className="close" style={{ position: 'absolute', top: '15px', right: '15px', zIndex:2, color:'#fff', opacity:1 }} onClick={() => props.toggle()}>&times;</button>
            <Row className="get-in-touch get-touch-popup row-eq-height">
                <Col lg="6">
                    <div className="section-head">
                        <h2>Get in Touch</h2>
                        <p>Connect with us to implement plausible technological solutions and move your business towards a successful venture. Leave a message below, and we’ll get you started right away.</p>
                        <div className="result full" style={{color:'green',textAlign:'center'}}>{formSubmitMesage}</div>
                    </div>
                    <form id="get-in-touch-form" name="get-in-touch-form" onSubmit={handleSubmit}>
                        <div className="half fullname">
                            <Input type="text" placeholder="Full Name" required value={userFullName}
                            onChange={(e) => {
                                setFullName(e.target.value)
                                setFullNameError(false);
                            }}
                            style={userFullNameError ? {borderColor:'red'} : {borderColor:'#ccc'}}
                            />
                        </div>
                        <div className="half email-address">
                            <Input type="email" placeholder="Email" required value={userEmail}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setEmailError(false);
                                    }}
                                    style={userEmailError ? {borderColor:'red'} : {borderColor:'#ccc'}} 
                            />
                        </div>
                        <div className="half services">
                            <select className="form-control" required value={userService ? userService : ''}
                                onChange={(e) => {
                                    setService(e.target.value)
                                    setServiceError(false)
                                }}
                                style={userServiceError ? {borderColor:'red'} : {borderColor:'#ccc'}}
                            >
                                <option value="">Select Service</option>
                                {/* <option value="Services">Services</option>
                                <option value="Services">Services</option>
                                <option value="Services">Services</option>
                                <option value="Services">Services</option> */}
                                {
                                     serviceData.length > 0 ? serviceData.map((response,i)=>{
                                        //   return  <img key={i} src={response.image}></img>
                                        return <option key={response.name} value={response.name}>{response.name}</option>
                                        }) : null

                                }
                            </select>
                        </div>
                        
                        <div className="half phone">
                        <Input type="tel" placeholder="Phone Number"  pattern="^\d{3}-\d{3}-\d{4}$|^\d{3}.\d{3}.\d{4}$" title="Phone should be in (xxx-xxx-xxxx) or (xxx.xxx.xxxx) format" oninvalid="this.setCustomValidity('Phone should be in (xxx-xxx-xxxx) or (xxx.xxx.xxxx) format')" required value={userPhone}  onChange={(e) => {
                                        setPhone(e.target.value)
                                        setPhoneError(false);
                                    }}
                                    style={userPhoneError ? {borderColor:'red'} : {borderColor:'#ccc'}} 
                            
                        />
                    </div>
                        
                        <div className="full message">
                            <Input type="textarea" required placeholder="Message"  value={userMessage}  onChange={(e) => {
                                setMessage(e.target.value)
                                setMessageError(false)
                            }}
                            style={userMessageError ? {borderColor:'red'} : {borderColor:'#ccc'}}
                            />
                        </div>
                        <div className="full submit-btn">
                            {
                                loader ? 'loading...' : <Button color="primary" type="submit" className="btn-animate">Connect</Button>
                            }
                        </div>
                    </form>
                </Col>
                <Col lg="6" className="get-intouch-img">
                    <img src="./Assets/Img/get-touch-popup-img.png"></img>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
   

        
    )
}

export default ModalComponent;