import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';

import 'bootstrap/dist/css/bootstrap.css';



import {Link, } from 'react-router-dom';

import {
    Container,
    Row,
    Col
  } from "reactstrap";

  function Healthcare(){

    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Healthcare</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Industries</Link></li>
                                    <li><span>Healthcare</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="solution-sections" fluid>
                <Row>
                    <Container>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Internet of Things</h2>
                                <ul>
                                    <li>Medical devices can collect invaluable data, give insight into symptoms and trends, enable remote care, and give patients more control over their lives and treatment</li>
                                    <li>Bluetooth-enabled weight scale and blood pressure cuff used with a symptom-tracking app that sends updates to patients’ physicians on symptoms and response to treatment</li>
                                    <li>Wireless remote temperature monitoring for vaccine refrigerator using  a sensor probe that uploads data on current temperature and grid power to text updates to monitoring personnel</li>
                                    <li>Air quality sensors</li>
                                    <li>Smart pill bottle that issues reminders for missed does via text or phone.</li>
                                </ul>
                                <a href="/internetofthings" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-iot.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-ai.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Artificial Intelligence</h2>
                                <ul>
                                    <li>AI to predict and diagnose disease at a faster rate than most medical professionals- reducing medical errors resulting in death</li>
                                    <li>Using algorithms and deep learning diagnosed cancer at a higher rate</li>
                                    <li>Using chatbot that listens to patients symptoms and health concerns, then guides that patient to the correct care based on its diagnosis</li>
                                </ul>
                                <a href="/artificialintelligence" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Cloud solution</h2>
                                <ul>
                                    <li>A cloud-based phone system where multiple employees across healthcare sector are able to communicate simultaneously, allowing to communicate in one system.</li>
                                    <li>Paperless access to information- single access point to patient information, allowing multiple user to view results or consult notes on patients. </li>
                                    <li>Collaboration tools allows for faster transfer of information, eliminating waiting time between doctors to receive info through traditional exchanges and spending more time on patient care and treatment option</li>
                                    <li>Using cloud to utilize its scalable storage that can store millions of patients information. – better data management</li>
                                    <li>Low cost – no hardware necessary</li>
                                </ul>
                                <a href="/cloudsolution" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-cloud.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/healthcare-digital-learning.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text">
                                <h2>Digital learning</h2>
                                <ul>
                                    <li>Employees usually participate in clinical updates, skill testing and health and safety programs in a classroom setting. Mandatory training can be set up through online</li>
                                    <li>Employers can provide education resource online via webinars.</li>
                                    <li>Virtual clinic models</li>
                                </ul>
                                <a href="/remotelearning" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Security & Surveillance Solution</h2>
                                <ul>
                                    <li>All-around premise security and surveillance</li>
                                    <li>Video intercom</li>
                                    <li>IP cameras</li>
                                    <li>CCTV Cameras</li>
                                    <li>Reducing risk of breach and increasing peace of mind</li>
                                </ul>
                                <a href="/security" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-unified.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/healthcare-cabling.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Cabling Infrastructure & Data Networking</h2>
                                <ul>
                                    <li>Proper cabling system support high-speed internet</li>
                                    <li>Network cabling, Voice cabling, System cabling, data center</li>
                                    <li>Complete system of cabling
                                        <ul>
                                            <li>Smart building – applying new technologies to solve real challenges</li>
                                            <li>Proper cabling to support fast emerging 5G network</li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="/infrastructure" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Paging System Solution</h2>
                                <ul>
                                    <li>Emergency paging system is a fast way for individuals with medical problems to get a quick response in times of medical need.</li>
                                    <li>Centralized clocks - Accurate time is critical in healthcare facilities: standardized schedules, timely medication disbursement, accurate medical record maintenance, complies with timekeeping regulations, etc</li>
                                </ul>
                                <a href="/pagingsystem" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-paging.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                 <img src="./Assets/Img/healthcare-avs.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Audio-Video Solution</h2>
                                <ul>
                                    <li>Used of IP-enabled professional AV for conference meetings across all board members</li>
                                </ul>
                                <a href="/audiovideo" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Wireless Connection / WiFi</h2>
                                <ul>
                                    <li>Internet connection is required in order to access patient information/data stored in the cloud</li>
                                    <li>File-sharing of patients information between physicians/doctors using cloud also requires connection to the internet</li>
                                    <li>Machine/tools used in healthcare field are now connected to the internet</li>
                                </ul>
                                <a href="/wirelessconnection" className="btn-animate">View Service</a>
                            </Col>
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-wireless.png"></img>
                            </Col>
                        </Row>
                        <Row className="row-eq-height solutions-row">
                            <Col lg="6" className="about-img solutions-img">
                                <img src="./Assets/Img/healthcare-telecommunication.png"></img>
                            </Col>
                            <Col lg="6" className="section-head about-text solutions-text security-service">
                                <h2>Telecom Support</h2>
                                <ul>
                                    <li>Most small healthcare clinics still runs on older generation phone system as upgrading to the cloud is not necessary for them. Support for these legacy phone systems is always available here at <span>ana Technology</span></li>
                                </ul>
                                <a href="/telecommunication" className="btn-animate">View Service</a>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            

            <Footer/>
        </Container>
    )
}

export default Healthcare;