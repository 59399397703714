import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';


import 'bootstrap/dist/css/bootstrap.css';
import FontAwesome from 'react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';


import {Link, useRouteMatch, useParams } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Input,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function InternetOfThings(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Internet of Things</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Internet of Things</h2>
                            <p>It’s the inclusion of internet connectivity to the everyday devices and appliances that have not typically had such capabilities. Turning those device or appliances into “Smart Objects”, capable of sending and receiving data and communicating with each other.</p>           
                            <ul>
                                <li>Computing device, mechanical and digital machines that are able to collect and transfer data over a wireless network</li>
                                <li>Motion sensor - Smart lighting</li>
                                <li>Sensors – detect space occupancy</li>
                                <li>IoT platform – acquire, analyze, transmit, solve, present</li>
                            </ul>
                            <p>Control your operation using any of your smart device</p>
                            <p>Wireless connection of multiple devices, all collecting and sharing data</p>
                            <p>Internet of Things allowing your company to get real time updates Saving your company time and money with automated machines</p>
                            <p>Smarter device/machine, simpler solution and operation</p>
                            <p>Future </p>
                            <ul>
                                <li>New technology such as 5G is expected to drive the market growth in the coming years</li>
                                <li>Public cloud service market expected to reach around 257.9 billion by the end of 2020</li>
                                <li>By 2022, cloud service market revenue is forecasted to exceed 364 billion</li>
                            </ul>
                            <p>Smart City</p>
                            <ul>
                                <li>An ecosystem where technology, innovation and collaboration intersect to create improved services and enhanced quality of life for the people.</li>
                                <li>Improve transportation and accessibility</li>
                                <li>Promote sustainability</li>
                                <li>Combining all “Smart objects” to build a Smart City.</li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                           <img src="./Assets/Img/education-iot.png"></img>
                            {/* <img src="./Assets/Img/internet-img.png"></img> */}
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default InternetOfThings;