import React, { useState, useEffect } from 'react';
import logo from './logo.png';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from './Views/Home';
import About from './Views/About';
import Contact from './Views/Contact';
import InternetOfThings from './Views/InternetOfThings';
import ArtificialIntelligence from './Views/ArtificialIntelligence';
import UnifiedCommunication from './Views/UnifiedCommunication'; 
import CloudSolution from './Views/CloudSolution';
import Security from './Views/Security';
import Infrastructure from './Views/Infrastructure';
import Turnkey from './Views/Turnkey';
import Rollout from './Views/Rollout';
import AudioVideo from './Views/AudioVideo';
import PagingSystem from './Views/PagingSystem';
import Consultation from './Views/Consultation';
import WirelessConnection from './Views/WirelessConnection';
import Telecommunication from './Views/Telecommunication';
import RemoteLearning from './Views/RemoteLearning';
import MonitoringService from './Views/MonitoringService';
import RenewableEnergy from './Views/RenewableEnergy';
import SIPTrunk from './Views/SIPTrunk';
import EverythingOther from './Views/EverythingOther';
import Education from './Views/Education';
import Retail from './Views/Retail';
import Financial from './Views/Financial';
import Healthcare from './Views/Healthcare';
import Manufacturing from './Views/Manufacturing';
import Transportation from './Views/Transportation';
import Hospitality from './Views/Hospitality';
import Restaurants from './Views/Restaurants';
import Careers from './Views/Careers';
import Terms from './Views/Terms';
import Privacy from './Views/Privacy';

function App(props) {

  const [ spinner, setSpinner ] = useState(true);

  useEffect(() => {
    setTimeout(() => props.addClass(), 10)
   setTimeout(() => props.hideLoader(), 3800)
   setTimeout(() => props.removeClass(), 3800)  
  }, []);

  

  return (

    // spinner ?  <div className="preloader"><img src={logo}/></div> : 
    
     // "homepage": "/anadigital/",
    <BrowserRouter basename='/'>
    {/* <BrowserRouter> */}
    {/* <BrowserRouter> */}
      <Switch>
        <Route exact  path="/" component={props => <Home {...props} />} />
        <Route   path="/about"  component={props => <About {...props} />} />
        <Route   path="/internetofthings"  component={props => <InternetOfThings {...props} />} />
        <Route   path="/artificialintelligence"  component={props => <ArtificialIntelligence {...props} />} />
        <Route   path="/contact"  component={props => <Contact {...props} />} />
        <Route   path="/unifiedcommunication"  component={props => <UnifiedCommunication {...props} />} />
        <Route   path="/cloudsolution"  component={props => <CloudSolution {...props} />} />
        <Route   path="/security"  component={props => <Security {...props} />} />
        <Route   path="/infrastructure"  component={props => <Infrastructure {...props} />} />
        <Route   path="/turnkey"  component={props => <Turnkey {...props} />} />
        <Route   path="/rollout"  component={props => <Rollout {...props} />} />
        <Route   path="/audiovideo"  component={props => <AudioVideo {...props} />} />
        <Route   path="/pagingsystem"  component={props => <PagingSystem {...props} />} />
        <Route   path="/consultation"  component={props => <Consultation {...props} />} />
        <Route   path="/wirelessconnection"  component={props => <WirelessConnection {...props} />} />
        <Route   path="/telecommunication"  component={props => <Telecommunication {...props} />} />
        <Route   path="/remotelearning"  component={props => <RemoteLearning {...props} />} />
        <Route   path="/monitoringservice"  component={props => <MonitoringService {...props} />} />
        <Route   path="/renewableenergy"  component={props => <RenewableEnergy {...props} />} />
        <Route   path="/siptrunk"  component={props => <SIPTrunk {...props} />} />
        <Route   path="/everything-as-a-service"  component={props => <EverythingOther {...props} />} />
        <Route   path="/education"  component={props => <Education {...props} />} />
        <Route   path="/retail"  component={props => <Retail {...props} />} />
        <Route   path="/financial"  component={props => <Financial {...props} />} />
        <Route   path="/healthcare"  component={props => <Healthcare {...props} />} />
        <Route   path="/manufacturing"  component={props => <Manufacturing {...props} />} />
        <Route   path="/transportation"  component={props => <Transportation {...props} />} />
        <Route   path="/hospitality"  component={props => <Hospitality {...props} />} />
        <Route   path="/restaurants"  component={props => <Restaurants {...props} />} />
        <Route   path="/careers"  component={props => <Careers {...props} />} />
        <Route   path="/terms"  component={props => <Terms {...props} />} />
        <Route   path="/privacy"  component={props => <Privacy {...props} />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
