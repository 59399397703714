import React, {Component, useState, useEffect } from "react";
import Header from './Header';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';

import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
  import ModalComponent from '../Components/ModalComponent';

  function RemoteLearning(){

    const [modal, setModal] = useState(false);
       
    function toggle(){
        setModal(!modal);
    }
    return(
        <Container className="wrapper" fluid>
            <Header/>

            <Container className="inner-slider" fluid>
                <Row>
                    <Container>
                        <Row>
                            <Col lg="12" className="subheader">
                                <h2 className="title">Services</h2>
                                <ul className="breadcrumb">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><span>Remote Learning</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>

            <Container className="service-sections">
                <Row>
                    <Col lg="6" className="section-head service-text">
                        
                            <h2>Remote Learning</h2>
                            <p>Why digital learning?</p> 
                            <ul>
                                <li>As technology evolves, our way of learning also evolves. From reading information in textbooks to searching information through the web, like Google. Technology has made it possible for students to search through millions of information from the palm of their hand rather than going through a traditional process of finding books in the library. </li>
                                <li>The inclusion of digital learning in the classrooms can vary from simply using tablets instead of paper to using elaborate software programs and equipment as opposed to the simple pen.</li>
                            </ul>
                            <p>Digital learning tools and technology enable educators to rapidly share information with other educators in real-time</p>
                            <p>Promotes remote learning</p>
                            <ul>
                                <li>Tools could include: Smart phone, Tablet, Desk computer, live streaming camera</li>
                                <li>While online education is already an option for most students, its importance has been more relevant during crisis.</li>
                                <li>To ensure the safety of students during the pandemic while continuing their education, schools have promoted to depend on online learning. As students became more proficient with how they use digital learning tools and technology prior to the pandemic, it is no wonder that students easily adapted to remote learning during the pandemic</li>
                                <li>The worldwide e-learning market is projected to be worth $325 Billion in 2025.</li>
                            </ul>
                            <p>Application software that supports communication and collaboration between students virtually.</p>
                            <p>Moving forward through digital learning,</p>
                            <p>Secure and easy to use.</p>
                            <p>Uses video conference calls to allow students to interact with teachers.</p>
                            <p>Chat, message, share files, task management, assign projects, all in one APP</p>
                            <p>Benefits:</p>
                            <ul>
                                <li>Accept more students</li>
                                <li>Accept Outside geographic students</li>
                                <li>Live In-class/digital class where other student whose option is to study from home.</li>
                                <li>Live class ideal for students who are outside the accepted geographic regions </li>
                            </ul>
                            <a onClick={toggle} className="btn-animate">Get in Touch</a>
                    </Col>
                    <Col lg="6" className="service-img">
                        
                            <img src="./Assets/Img/digital-learning.png"></img>
                        
                    </Col>
                    
                </Row>
            </Container>
            <ModalComponent modal={modal} toggle={() => toggle()}/>
            <Footer/>
        </Container>
    )
}

export default RemoteLearning;